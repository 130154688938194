import { getEmbeddingFromDTO } from 'features/embeddings';
import type { AnswerDTO } from './types';
import type { Answer } from '../types';

export function getAnswerFromDTO(dto: AnswerDTO): Answer {
  const result: Answer = {
    ...dto,
    references: dto.references.map((reference) => getEmbeddingFromDTO(reference)),
  };

  return result;
}
