import theme from 'theme';
import config from 'config';
import { Titled } from 'react-titled';
import queryString from 'query-string';
import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import { queryClient } from 'lib/react-query';
import { QueryParamProvider } from 'use-query-params';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import AuthContextProvider from 'lib/auth/AuthContextProvider';

import GlobalErrorBoundary from 'GlobalErrorBoundary';
import { AppLayout, LayoutSkeleton } from 'components';

/** Pages */
import EmbeddingsListRoute from 'pages/knowledge-base';
import EmbeddingsDetailRoute from 'pages/knowledge-base/detail';
import CreateEmbeddingRoute from 'pages/knowledge-base/add';
import ViewEmbeddingRoute from 'pages/knowledge-base/view';
import EditEmbeddingRoute from 'pages/knowledge-base/edit';
import EmbeddingsMetricsRoute from 'pages/knowledge-base/metrics';
import QNARoute from 'pages/q-and-a';
import PromptsListRoute from 'pages/prompts';
import CreatePromptRoute from 'pages/prompts/add';
import ViewPromptRoute from 'pages/prompts/view';
import EditPromptRoute from 'pages/prompts/edit';
import FrontappConfigRoute from 'pages/frontapp/config';
import FrontappConfigIndexRoute from 'pages/frontapp/config-index';
import FrontappMetricsRoute from 'pages/frontapp/metrics';
import FrontappRedirect from 'pages/frontapp/frontapp-layout';
import FrontappLogsRoute from 'pages/frontapp/frontapp-logs';
import FrontappLogViewRoute from 'pages/frontapp/frontapp-log-view';
import ProductsRoute from 'pages/contact-form/products';
import ProductByIdRoute from 'pages/contact-form/product-by-id';
import CategoriesRoute from 'pages/contact-form/categories';
import CategoryByIdRoute from 'pages/contact-form/category-by-id';
import ContactFormRedirect from 'pages/contact-form/contact-form-layout';

const queryProviderOptions = {
  searchStringToObject: queryString.parse,
  objectToSearchString: queryString.stringify,
};

const AppProviders: React.FC = () => (
  <ThemeProvider theme={theme}>
    <QueryParamProvider adapter={ReactRouter6Adapter} options={queryProviderOptions}>
      <CssBaseline />
      <Titled title={config.title}>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalErrorBoundary>
              <SnackbarProvider
                maxSnack={5}
                autoHideDuration={1000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              >
                <Suspense
                  fallback={
                    <LayoutSkeleton>
                      <LinearProgress sx={{ mx: -3 }} />
                    </LayoutSkeleton>
                  }
                >
                  <AppLayout>
                    <Outlet />
                  </AppLayout>
                </Suspense>
              </SnackbarProvider>
            </GlobalErrorBoundary>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AuthContextProvider>
      </Titled>
    </QueryParamProvider>
  </ThemeProvider>
);

const router = createBrowserRouter([
  {
    element: <AppProviders />,
    children: [
      {
        path: '/',
        children: [
          { index: true, element: <Navigate to="/knowledge-bases" replace /> },
          {
            path: 'knowledge-bases',
            element: <EmbeddingsListRoute />,
          },
          {
            path: 'knowledge-bases/:indexPath/metrics',
            element: <EmbeddingsMetricsRoute />,
          },
          {
            path: 'knowledge-bases/:indexPath',
            element: <EmbeddingsDetailRoute />,
            children: [
              { path: 'add', element: <CreateEmbeddingRoute /> },
              { path: 'metrics', element: <EmbeddingsMetricsRoute /> },
              { path: ':embeddingId', element: <ViewEmbeddingRoute /> },
              { path: ':embeddingId/edit', element: <EditEmbeddingRoute /> },
            ],
          },
          {
            path: 'prompts',
            element: <PromptsListRoute />,
            children: [
              { path: 'add', element: <CreatePromptRoute /> },
              { path: ':promptId', element: <ViewPromptRoute /> },
              { path: ':promptId/edit', element: <EditPromptRoute /> },
            ],
          },
          {
            path: 'ask-me-something',
            element: <QNARoute />,
            children: [{ path: ':indexPath/references/:embeddingId', element: <ViewEmbeddingRoute /> }],
          },
          {
            path: 'frontapp',
            element: <FrontappRedirect />,
            children: [
              {
                path: 'configuration',
                element: <FrontappConfigRoute />,
              },
              {
                path: 'configuration/:inboxId',
                element: <FrontappConfigIndexRoute />,
              },
              {
                path: 'metrics',
                element: <FrontappMetricsRoute />,
              },
              {
                path: 'logs',
                element: <FrontappLogsRoute />,
                children: [
                  {
                    path: ':logId',
                    element: <FrontappLogViewRoute />,
                  },
                ],
              },
            ],
          },
          {
            path: 'contact-form',
            element: <ContactFormRedirect />,
            children: [
              {
                path: 'products',
                element: <ProductsRoute />,
                children: [
                  {
                    path: ':productId',
                    element: <ProductByIdRoute />,
                  },
                ],
              },
              {
                path: 'categories',
                element: <CategoriesRoute />,
                children: [
                  {
                    path: ':categoryId',
                    element: <CategoryByIdRoute />,
                  },
                ],
              },
            ],
          },
          { path: '*', element: <Navigate to="/knowledge-bases" replace /> },
        ],
      },
    ],
  },
]);

const App = () => (
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<div>Houston, we have a problem loading</div>} />
  </React.StrictMode>
);

export default App;
