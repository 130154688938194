import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import type { CreatePromptPayload, PromptDTO } from './types';
import queryKeys from './queryKeys';
import getPromptsFromDTO from './getPromptsFromDTO';
import type { Prompt } from '../types';

type CreatePromptOptions = {
  data: CreatePromptPayload;
};

export const createPrompt = async ({ data }: CreatePromptOptions): Promise<Prompt> => {
  const res = await apiClient.post<PromptDTO>(`/prompt`, data);
  return getPromptsFromDTO(res.data);
};

type MutationFnType = (data: CreatePromptPayload) => ReturnType<typeof createPrompt>;

type UseCreateProjectPromptOptions = {
  config?: MutationConfig<MutationFnType>;
};

export const useCreatePrompt = ({ config }: UseCreateProjectPromptOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys().list() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (data: CreatePromptPayload) => createPrompt({ data }),
  });
};
