import { Box, Stack, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import type { CreateEmbeddingPayload, QuestionEmbeddingPayload } from '../api';

const QuestionForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateEmbeddingPayload<QuestionEmbeddingPayload>>();

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="overline">Question</Typography>
        <TextField
          multiline
          fullWidth
          minRows={3}
          maxRows={3}
          placeholder="Question here"
          error={Boolean(errors.value?.question)}
          helperText={errors.value?.question?.message}
          {...register('value.question', { shouldUnregister: true })}
        />
      </Box>
      <Box>
        <Typography variant="overline">Answer</Typography>
        <TextField
          multiline
          fullWidth
          minRows={15}
          maxRows={30}
          placeholder="Answer to the question above"
          error={Boolean(errors.value?.answer)}
          helperText={errors.value?.answer?.message}
          {...register('value.answer', { shouldUnregister: true })}
        />
      </Box>
    </Stack>
  );
};

export default QuestionForm;
