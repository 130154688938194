import { useEffect, type EffectCallback, type DependencyList, useRef } from 'react';

export const useEffectAfterMount = (effect: EffectCallback, deps: DependencyList): void => {
  const isInitialLoading = useRef(true);

  useEffect(() => {
    if (isInitialLoading.current) {
      isInitialLoading.current = false;
    } else {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
