import type { FC } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, type SelectProps } from '@mui/material';
import { EmbeddingType } from 'features/embeddings';

type TypesSelectorOwnProps = {
  indexPath?: string;
  blankOptionText?: string | null;
  helperText?: string;
};

const TypesSelector: FC<Omit<SelectProps<number | null>, 'variant'> & TypesSelectorOwnProps> = ({
  blankOptionText = 'All types',
  helperText,
  ...props
}) => {
  const withoutBlankOption = blankOptionText === null;

  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel>Types</InputLabel>
      <Select label="types" placeholder="Types" {...props}>
        {!withoutBlankOption && <MenuItem value={undefined}>{blankOptionText}</MenuItem>}
        <MenuItem value={EmbeddingType.Text}>Text</MenuItem>
        <MenuItem value={EmbeddingType.Question}>Question</MenuItem>
      </Select>
      {helperText && props.error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TypesSelector;
