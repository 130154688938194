import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import type { Answer } from '../types';
import { getAnswerFromDTO } from './getAnswerFromDTO';
import type { AnswerDTO, GenerateAnswerPayload } from './types';

type GenerateAnswerOptions = {
  data: GenerateAnswerPayload;
};

export const generateAnswer = async ({ data }: GenerateAnswerOptions): Promise<Answer> => {
  const res = await apiClient.post<AnswerDTO>(`/query/`, data);
  return getAnswerFromDTO(res.data);
};

type MutationFnType = (data: GenerateAnswerPayload) => ReturnType<typeof generateAnswer>;

type UseGenerateProjectAnswerOptions = {
  config?: MutationConfig<MutationFnType>;
};

export const useGenerateAnswer = ({ config }: UseGenerateProjectAnswerOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: (data: GenerateAnswerPayload) => generateAnswer({ data }),
  });
};
