import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { SidePageLayout } from 'components';
import { useMemo, type FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CategorySelector, EmbeddingType } from '..';
import type { UpdateEmbeddingPayload } from '../api';
import { embeddingToDTO, useGetEmbedding, useUpdateEmbedding } from '../api';
import { embeddingValidation } from '../validation/embeddingsValidation';
import QuestionForm from './QuestionForm';
import TextForm from './TextForm';
import { StringParam, useQueryParam } from 'use-query-params';

interface EditEmbeddingPageProps {
  embeddingId: string;
}

const EditEmbeddingPage: FC<EditEmbeddingPageProps> = ({ embeddingId }) => {
  const navigate = useNavigate();
  const { indexPath } = useParams();
  const [redirectUrl] = useQueryParam('redirect', StringParam);

  const embeddingQuery = useGetEmbedding({ indexPath: indexPath!, embeddingId });
  const embedding = embeddingQuery.data!;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      value: useMemo(() => {
        const value = embeddingToDTO(embedding).value;

        return {
          ...value,
          category: value.category ?? undefined,
        };
      }, [embedding]),
    },
    resolver: yupResolver(embeddingValidation),
  });

  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const category = watch('value.category');

  const { mutate: updateEmbedding, isPending: isUpdating } = useUpdateEmbedding({
    embeddingId,
    indexPath: indexPath!,
    config: { onSuccess: () => navigate(`../${embeddingId}`) },
  });

  const onSubmit = (data: UpdateEmbeddingPayload) => {
    const { value } = data;

    updateEmbedding({
      value: { ...value, category: value.category ?? null },
    });
  };

  const createEmbeddingsFooter = (
    <>
      <Button variant="text" size="large" component={Link} to={redirectUrl ?? `..`}>
        Cancel
      </Button>
      <LoadingButton
        loading={isUpdating}
        variant="contained"
        size="large"
        sx={{ ml: 'auto' }}
        type="submit"
        disabled={!isValid || !isDirty}
      >
        Save
      </LoadingButton>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SidePageLayout fullHeight title={null} name="Edit Embedding" footer={createEmbeddingsFooter}>
            <Box sx={{ mt: 3 }}>
              <Box mb={2}>
                <Controller
                  control={control}
                  name="value.category"
                  render={({ field, fieldState }) => (
                    <CategorySelector
                      fullWidth
                      {...field}
                      blankOptionText={null}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={category}
                    />
                  )}
                />
              </Box>
              {embedding.value.type === EmbeddingType.Question && <QuestionForm />}
              {embedding.value.type === EmbeddingType.Text && <TextForm />}
            </Box>
          </SidePageLayout>
        </form>
      </FormProvider>
    </Box>
  );
};
export default EditEmbeddingPage;
