import { type FC, Suspense } from 'react';
import { DrawerPage } from 'components';
import { ViewEmbeddingPage, EmbeddingPageSkeleton } from 'features/embeddings';
import { useParams } from 'react-router';

const ViewEmbeddingRoute: FC = () => {
  const { embeddingId } = useParams();
  return (
    <DrawerPage>
      <Suspense fallback={<EmbeddingPageSkeleton />}>
        <ViewEmbeddingPage embeddingId={embeddingId!} />
      </Suspense>
    </DrawerPage>
  );
};

export default ViewEmbeddingRoute;
