import { type FC, Suspense } from 'react';
import { DrawerPage } from 'components';
import { Box } from '@mui/material';
import { CreatePromptPage } from 'features/prompts';

const CreatePromptRoute: FC = () => (
  <DrawerPage>
    <Suspense fallback={<Box>Loading skeleton will be addedd</Box>}>
      <CreatePromptPage />
    </Suspense>
  </DrawerPage>
);

export default CreatePromptRoute;
