import { useMatches } from 'react-router';
import { useEffect, useMemo, useState } from 'react';

type IsolatorOptions = { nestedLevel: number };

export const useRouteDataIsolator = <T>(
  rootData: T,
  options: IsolatorOptions = {
    nestedLevel: 3,
  }
) => {
  const metches = useMatches();

  const [isolatedData, setData] = useState<T>(rootData);
  const [listQuery, setQuery] = useState(new URLSearchParams(window.location.search));

  const isNestedRoute = metches.length > options.nestedLevel;

  useEffect(() => {
    if (!isNestedRoute) {
      setData(rootData);
      setQuery(new URLSearchParams(window.location.search));
    }
  }, [isNestedRoute, rootData]);

  return useMemo(
    () => ({
      data: isNestedRoute ? isolatedData : rootData,
      listQuery,
    }),
    [isolatedData, isNestedRoute, listQuery, rootData]
  );
};
