import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Suspense, lazy, useMemo } from 'react';
import { Add as AddIcon, Assessment } from '@mui/icons-material';
import { Box, Button, Paper, TextField, type SelectChangeEvent, Stack } from '@mui/material';
import { useEffectAfterMount, useRouteDataIsolator, usePagination, useSearch, useSorting } from 'hooks';
import { type EmbeddingsOrderByFields, CategorySelector, TypesSelector } from 'features/embeddings';
import { ListSkeleton, PageLayout, TableListErrorBoundary } from 'components';
import { useQueryParam, NumberParam, withDefault, StringParam } from 'use-query-params';
import { useAuth } from 'lib/auth/AuthContext';
import useTypedParams from 'utils/useTypedParams';

const EmbeddingsList = lazy(() => import('features/embeddings/components/EmbeddingsList'));

const EmbeddingsDetailRoute: FC = () => {
  const { isViewer } = useAuth();
  const { indexPath } = useTypedParams({ indexPath: String });

  const [search, searchFieldValue, handleSearchFieldChange, resetSearch] = useSearch();
  useEffectAfterMount(resetSearch, [indexPath, resetSearch]);

  const [ids, setIds] = useQueryParam('ids', withDefault(StringParam, undefined));
  const [type, setType] = useQueryParam('type', withDefault(NumberParam, undefined));
  const [category, setCategory] = useQueryParam('category', withDefault(NumberParam, undefined));
  const sorting = useSorting<EmbeddingsOrderByFields>();
  const pagination = usePagination([search, category, sorting.field, sorting.order]);

  const handleCategoryChange = (event: SelectChangeEvent<number | null>) => {
    setCategory(event.target.value as number | null);
  };

  const handleTypeChange = (event: SelectChangeEvent<number | null>) => {
    setType(event.target.value as number | null);
  };

  const { data, listQuery } = useRouteDataIsolator(
    useMemo(
      () => ({
        ids,
        type,
        search,
        sorting,
        category,
        pagination,
      }),
      [ids, type, search, sorting, category, pagination]
    )
  );

  return (
    <PageLayout
      outlet
      outletContext={{ listQuery }}
      name="Knowledge Base"
      headerActions={
        <Stack direction="row" gap={2}>
          <Button variant="text" disabled={isViewer} component={Link} to="metrics" startIcon={<Assessment />}>
            Metrics
          </Button>
          <Button variant="contained" disabled={isViewer} component={Link} to="add" startIcon={<AddIcon />}>
            Create
          </Button>
        </Stack>
      }
    >
      <Box sx={{ py: 2, display: 'flex' }} gap={1}>
        <TextField
          label="Search"
          placeholder="Search term"
          onChange={handleSearchFieldChange}
          value={searchFieldValue}
        />
        <CategorySelector
          key={`category-${indexPath}`}
          sx={{ width: 195 }}
          value={data.category}
          onChange={handleCategoryChange}
        />
        <TypesSelector key={`type-${indexPath}`} sx={{ width: 195 }} value={data.type} onChange={handleTypeChange} />

        {data.ids && (
          <Button sx={{ ml: 2, alignSelf: 'center' }} onClick={() => setIds(undefined)}>
            Reset filter by ID
          </Button>
        )}
      </Box>
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableListErrorBoundary>
          <Suspense
            fallback={
              <ListSkeleton
                titles={[
                  { field: '', width: 50 },
                  { field: 'ID', width: 80 },
                  { field: 'Value', width: '45%' },
                  { field: 'Category' },
                  { field: 'Type' },
                  { field: 'Status' },
                  { field: 'Created' },
                  { field: 'Updated' },
                  { field: '', width: 70 },
                ]}
                size={10}
              />
            }
          >
            <EmbeddingsList
              pagination={data.pagination}
              category={data.category}
              sorting={data.sorting}
              query={data.search}
              type={data.type}
              ids={data.ids}
            />
          </Suspense>
        </TableListErrorBoundary>
      </Paper>
    </PageLayout>
  );
};

export default EmbeddingsDetailRoute;
