export enum PromptStatus {
  Unverified = 0,
  Verified = 1,
}

export interface Prompt {
  id: number;
  name: string;
  content: string;
  createdBy: string;
  createdAt: Date;
  updatedBy: string | null;
  updatedAt: Date | null;
  validatedBy: string | null;
  status: PromptStatus;
}
