import type { SxProps, Theme } from '@mui/material';
import { Chip, alpha } from '@mui/material';
import { EmbeddingStatus } from 'features/embeddings';
import type { FC } from 'react';

const embeddingStatusToSx: Record<EmbeddingStatus, SxProps<Theme>> = {
  [EmbeddingStatus.Verified]: (theme) => ({
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    color: theme.palette.success.main,
  }),
  [EmbeddingStatus.Unverified]: (theme) => ({
    backgroundColor: alpha(theme.palette.warning.main, 0.2),
    color: theme.palette.warning.main,
  }),
};

const embeddingStatusToLabel: Record<EmbeddingStatus, string> = {
  [EmbeddingStatus.Verified]: 'Validated',
  [EmbeddingStatus.Unverified]: 'Not Validated',
};

interface EmbeddingStatusChipProps {
  status: EmbeddingStatus;
}

const EmbeddingStatusChip: FC<EmbeddingStatusChipProps> = ({ status }) => (
  <Chip label={embeddingStatusToLabel[status]} sx={embeddingStatusToSx[status]} />
);
export default EmbeddingStatusChip;
