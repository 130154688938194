import { useMemo, type FC } from 'react';
import { useMatch } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router';
import { Stack, Typography } from '@mui/material';
import { PageLayout, HeadLink } from 'components';

const FrontappRedirect: FC = () => {
  const isConfiguration = useMatch('/frontapp/configuration/*');
  const isLogs = useMatch('/frontapp/logs/*');
  const isMetrics = useMatch('/frontapp/metrics/*');

  const nameSwitcher = useMemo(() => {
    return (
      <Typography variant="h5" textTransform="capitalize" fontWeight="bold">
        <Stack direction="row" alignItems="center" gap={3}>
          <HeadLink to="/frontapp/configuration">Configuration</HeadLink>
          <HeadLink to="/frontapp/logs">Logs</HeadLink>
          <HeadLink to="/frontapp/metrics">Metrics</HeadLink>
        </Stack>
      </Typography>
    );
  }, []);

  if (!isConfiguration && !isLogs && !isMetrics) {
    return <Navigate to="/frontapp/configuration" />;
  }

  return (
    <PageLayout name={nameSwitcher}>
      <Outlet />
    </PageLayout>
  );
};

export default FrontappRedirect;
