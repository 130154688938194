export enum EmbeddingStatus {
  Unverified = 0,
  Verified = 1,
}

export enum EmbeddingType {
  Text = 0,
  Question = 1,
}

export interface TextEmbedding {
  type: EmbeddingType.Text;
  category: number | null;
  text: string;
}

export interface QuestionEmbedding {
  type: EmbeddingType.Question;
  category: number | null;
  question: string;
  answer: string;
}

export interface Embedding {
  id: string;
  value: TextEmbedding | QuestionEmbedding;
  score: number;
  index_name: string;
  createdBy: string | null;
  createdAt: Date;
  updatedBy: string | null;
  validatedBy: string | null;
  updatedAt: Date | null;
  status: EmbeddingStatus;
}
