import type { MouseEvent, ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';
import { useEffectAfterMount } from './useEffectAfterMount';

export interface UsePaginationResult {
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  handlePageChange: (event: MouseEvent<HTMLButtonElement> | null, nextPage: number) => void;
  handleRowsPerPageChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resetPagination: () => void;
}

export function usePagination(resetDependencies: readonly unknown[] = [], defaultPerPage = 25): UsePaginationResult {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));
  const [perPage, setPerPage] = useQueryParam('perPage', withDefault(NumberParam, defaultPerPage));

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, nextPage: number) => {
      setPage(nextPage);
    },
    [setPage]
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPerPage(Number(event.target.value));
      setPage(0);
    },
    [setPerPage, setPage]
  );

  const resetPagination = useCallback(() => {
    setPage(0, 'replaceIn');
  }, [setPage]);

  useEffectAfterMount(
    () => resetPagination(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetDependencies
  );

  return useMemo(
    () => ({
      page,
      perPage,
      setPerPage,
      handlePageChange,
      handleRowsPerPageChange,
      resetPagination,
    }),
    [page, perPage, setPerPage, handlePageChange, handleRowsPerPageChange, resetPagination]
  );
}
