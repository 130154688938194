import { ArticleOutlined, DeleteOutline, EditOutlined, MoreVert } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useAnchorMenu, useModal } from 'hooks';
import { useCallback, type FC, type MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import type { Prompt } from '../types';
import PromptStatusChip from './PromptStatusChip';
import { format } from 'date-fns';
import ConfirmEntityDeleteDialog from 'components/ConfirmEntityDeleteDialog';
import { useDeletePrompt } from '../api/deletePrompt';
import { useAuth } from 'lib/auth/AuthContext';
import handleEntityDeletionError from 'utils/handleEntityDeletionError';

const ThreeLineBox = styled(Box)`
  overflow: hidden;
  box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

interface PromptListRowProps {
  prompt: Prompt;
}

const PromptListRow: FC<PromptListRowProps> = ({ prompt }) => {
  const menu = useAnchorMenu();
  const deleteModal = useModal();
  const { isViewer } = useAuth();

  const handlePreventDefault = (event: MouseEvent) => {
    event.preventDefault();
  };

  const { mutate, isPending } = useDeletePrompt({
    promptId: prompt.id,
    config: { onSuccess: () => deleteModal.close(), onError: handleEntityDeletionError },
  });

  const handleDeleteMenuClick = useCallback(() => {
    menu.handleCloseMenu();
    deleteModal.open();
  }, [menu, deleteModal]);

  return (
    <>
      <ConfirmEntityDeleteDialog
        entityName="Prompt"
        entityId={prompt.id}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.close}
        isLoading={isPending}
        onDelete={() => mutate()}
      />
      <TableRow
        hover
        component={Link}
        to={`${prompt.id}`}
        role="link"
        sx={{
          display: 'table-row',
          textDecoration: 'none',
        }}
      >
        <TableCell>{prompt.id}</TableCell>
        <TableCell>
          <Typography>
            <ThreeLineBox>{prompt.name}</ThreeLineBox>
          </Typography>
        </TableCell>
        <TableCell variant="body">
          <ThreeLineBox>
            <Typography>{prompt.content} </Typography>
          </ThreeLineBox>
        </TableCell>
        <TableCell>
          <PromptStatusChip status={prompt.status} />
        </TableCell>
        <TableCell>{format(prompt.createdAt, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell>{prompt.updatedAt && format(prompt.updatedAt, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell sx={{ textAlign: 'right' }} onClick={handlePreventDefault}>
          <IconButton onClick={menu.handleOpenMenu}>
            <MoreVert />
          </IconButton>

          <Menu
            anchorEl={menu.anchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menu.isOpen}
            onClose={menu.handleCloseMenu}
          >
            <MenuItem component={Link} to={`${prompt.id}`} onClick={menu.handleCloseMenu}>
              <ListItemIcon>
                <ArticleOutlined />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>
            <MenuItem component={Link} disabled={isViewer} to={`${prompt.id}/edit`} onClick={menu.handleCloseMenu}>
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem disabled={isViewer} onClick={handleDeleteMenuClick}>
              <ListItemIcon>
                <DeleteOutline color="error" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="error">Delete</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PromptListRow;
