import type { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

export default function handleEntityDeletionError(e: AxiosError) {
  enqueueSnackbar(`Entity deletion failed. An error returned from the server: (${e.message})`, {
    variant: 'error',
    autoHideDuration: 5000,
    anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
  });
}
