import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type { APIListResponse, ListResponse } from 'api';
import { getAPIListResponse, apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import type { Prompt } from '../types';
import queryKeys from './queryKeys';
import type { PromptDTO, PromptQueryParams } from './types';
import getPromptFromDTO from './getPromptsFromDTO';

interface GetPromptsOptions {
  params: PromptQueryParams;
}

export const getPrompts = async (
  { params }: GetPromptsOptions,
  signal?: AbortSignal
): Promise<ListResponse<Prompt>> => {
  const res = await apiClient.get<APIListResponse<PromptDTO>>(`/prompt`, {
    params,
    signal,
  });

  return getAPIListResponse({
    ...res.data,
    items: res.data.items.map((emb) => getPromptFromDTO(emb)),
  });
};

export type UseGetPromptsQuery = {
  params: PromptQueryParams;
  config?: QueryConfig<typeof getPrompts>;
};

export const useGetSynchronousPrompts = ({ config, params }: UseGetPromptsQuery) =>
  useQuery({
    staleTime: 60 * 1000,
    ...config,
    queryKey: queryKeys().list(params),
    queryFn: ({ signal }) => getPrompts({ params }, signal),
  });

export const useGetPrompts = ({ config, params }: UseGetPromptsQuery) =>
  useSuspenseQuery({
    staleTime: 60 * 1000,
    ...config,
    queryKey: queryKeys().list(params),
    queryFn: ({ signal }) => getPrompts({ params }, signal),
  });
