import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import type { EmbeddingCategory } from './types';
import queryKeys from './queryKeys';

type GetCategoriesOptions = {
  indexPath: string;
};

export const getCategories = async ({ indexPath }: GetCategoriesOptions, signal?: AbortSignal) => {
  const res = await apiClient.get<EmbeddingCategory[]>(`/${indexPath}/embeddings/categories`, { signal });
  return res.data;
};

export type UseGetCategoriesQuery = {
  indexPath: string;
  config?: QueryConfig<typeof getCategories>;
};

export const useGetCategories = ({ indexPath, config }: UseGetCategoriesQuery) =>
  useQuery({
    ...config,
    queryKey: queryKeys(indexPath).categories(),
    queryFn: ({ signal }) => getCategories({ indexPath }, signal),
  });
