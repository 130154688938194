import { useParams } from 'react-router';
import { useGetCategories } from '../api';

const useGetCategoryName = (id?: number | null) => {
  const { indexPath } = useParams();

  const { data: categories } = useGetCategories({ indexPath: indexPath! });

  return id ? categories?.find((category) => category.id === id)?.value.replace(/_/gi, ' ') : '-';
};

export default useGetCategoryName;
