import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import queryKeys from './queryKeys';

type GetFrontappHistoryOptions = {
  conversationId: string;
  lastMessageId: string;
};

type FrontappHistory = Array<{
  id: string;
  is_draft: boolean;
  is_inbound: boolean;
  text: string;
}>;

export const getFrontappHistory = async (
  { conversationId, lastMessageId }: GetFrontappHistoryOptions,
  signal?: AbortSignal
): Promise<FrontappHistory> => {
  const res = await apiClient.get(
    `/frontapp/conversation/${conversationId}/messages?last_message_id=${lastMessageId}`,
    { signal }
  );
  return res.data;
};

type MutationFnType = () => ReturnType<typeof getFrontappHistory>;

type UseGetFrontappHistoryOptions = {
  conversationId: string;
  lastMessageId: string;
  config?: QueryConfig<MutationFnType>;
};

export const useGetFrontappHistory = ({ config, conversationId, lastMessageId }: UseGetFrontappHistoryOptions) => {
  return useQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getFrontappHistory({ conversationId, lastMessageId }, signal),
    queryKey: queryKeys().frontAppHistory(conversationId, lastMessageId),
  });
};
