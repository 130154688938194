import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import queryKeys from './queryKeys';

interface GetMetricsOptions {
  indexPath: string;
}

type Metrics = {
  categories: Record<string, number>;
  editors: Record<string, number>;
  types: Record<string, number>;
  first_document_created_at: string;
  index: string;
  last_document_created_at: string;
  statuses: { validated?: number; not_validated?: number };
  total_documents: number;
};

export const getMetrics = async ({ indexPath }: GetMetricsOptions, signal?: AbortSignal) => {
  const res = await apiClient.get<Metrics>(`/knowledge-bases/${indexPath}`, { signal });
  return res.data;
};

export type UseGetMetricsQuery = {
  indexPath: string;
  config?: QueryConfig<typeof getMetrics>;
};

export const useGetMetrics = ({ config, indexPath }: UseGetMetricsQuery) =>
  useSuspenseQuery({
    ...config,
    queryKey: queryKeys(indexPath).metrics(),
    queryFn: ({ signal }) => getMetrics({ indexPath }, signal),
  });
