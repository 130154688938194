/* eslint-disable react/display-name */

import type { ComponentType } from 'react';
import type React from 'react';
import { Suspense } from 'react';

const withSuspense =
  <P extends object>(WrappedComponent: ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <Suspense>
        <WrappedComponent {...props} />
      </Suspense>
    );

export default withSuspense;
