import { useMemo, type FC, useCallback } from 'react';
import { BarChart, LineChart } from '@mui/x-charts';
import { format, subDays } from 'date-fns';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import withSuspense from 'utils/withSuspense';
import { FrontappLogStatuses, useGetFrontappMetrics, useGetSyncFrontappMetrics } from 'features/frontapp';
import { NumberParam, StringParam, useQueryParam, useQueryParams, withDefault } from 'use-query-params';
import { cloneDeep, isArray, isEmpty, keys, mergeWith, omitBy, sum } from 'lodash-es';
import { lighten } from 'polished';

enum TimeFilters {
  WEEK = 'week',
  WEEKx2 = '2weeks',
  WEEKx3 = '3weeks',
}

const FrontappMetricsRoute: FC = () => {
  const t = useTheme();

  const columnColor = t.palette.primary.light;
  const columnColorWithFilter = lighten(0.1, t.palette.error.light);

  const [hideInitial, setHideInitial] = useQueryParam('hide_initial', withDefault(NumberParam, undefined));

  const [query, setQuery] = useQueryParams({
    status: withDefault(StringParam, ''),
    inbox_email: withDefault(StringParam, ''),
    time_filter: withDefault(StringParam, ''),
  });

  const filters = useMemo<any>(() => omitBy(query, isEmpty), [query]);
  const isTimeFilterEq = (marker: TimeFilters) => query.time_filter === marker;

  const { isFilterApplied, resetFilters } = useMemo(
    () => ({
      isFilterApplied: Object.values(query).some(Boolean),
      resetFilters: () => setQuery({ status: undefined, inbox_email: undefined, time_filter: undefined }),
    }),
    [query, setQuery]
  );

  const isInitialShown = !hideInitial || !isFilterApplied;

  const fromDate = useMemo<string | undefined>(() => {
    if (filters.time_filter === TimeFilters.WEEK) {
      return format(subDays(new Date(), 7), 'yyyy-MM-dd');
    }
    if (filters.time_filter === TimeFilters.WEEKx2) {
      return format(subDays(new Date(), 7 * 2), 'yyyy-MM-dd');
    }
    if (filters.time_filter === TimeFilters.WEEKx3) {
      return format(subDays(new Date(), 7 * 3), 'yyyy-MM-dd');
    }
    return undefined;
  }, [filters.time_filter]);

  const { data: initialMetrics } = useGetFrontappMetrics();
  const { data: metrics } = useGetSyncFrontappMetrics({
    params: { status: filters.status, from_date: fromDate, inbox_email: filters.inbox_email },
  });

  const initialTotal = sum(Object.values(initialMetrics.status));
  const filteredTotal = sum(Object.values(metrics.status));

  const mergedMetrics = useMemo(() => {
    const { created_at, inbox_email, status, indexes, related_documents } = cloneDeep(initialMetrics);

    return {
      createdAt: mergeWith(created_at, metrics.created_at, (oVal, srcVal) => [oVal, srcVal]),
      inboxEmails: mergeWith(inbox_email, metrics.inbox_email, (oVal, srcVal) => [oVal, srcVal]),
      status: mergeWith(status, metrics.status, (oVal, srcVal) => [oVal, srcVal]),
      indexes: mergeWith(indexes, metrics.indexes, (oVal, srcVal) => [oVal, srcVal]),
      relatedDocuments: mergeWith(related_documents, metrics.related_documents, (oVal, srcVal) => [oVal, srcVal]),
    };
  }, [initialMetrics, metrics]);

  const getStructuredData = useCallback(
    (data: Record<string, number>) => [
      ...(isInitialShown
        ? [
            {
              label: 'Initial data',
              data: Object.values(data).map((i) => (isArray(i) ? i[0] : i)),
            },
          ]
        : []),
      ...(isFilterApplied
        ? [
            {
              label: 'Filtered data',
              data: Object.values(data).map((i) => (isArray(i) ? i[1] : 0)),
            },
          ]
        : []),
    ],
    [isInitialShown, isFilterApplied]
  );

  const countsByDay = useMemo(
    () =>
      isInitialShown
        ? [
            {
              area: true,
              label: `Initial data (total: ${initialTotal})`,
              data: Object.values(mergedMetrics.createdAt).map((i) => (isArray(i) ? i[0] : i)),
            },
            ...(isFilterApplied
              ? [
                  {
                    area: true,
                    label: `Filtered data (total: ${filteredTotal})`,
                    data: Object.values(mergedMetrics.createdAt).map((i) => (isArray(i) ? i[1] : 0)),
                  },
                ]
              : []),
          ]
        : [
            {
              area: true,
              label: `Filtered data (total: ${filteredTotal})`,
              data: Object.values(metrics.created_at),
            },
          ],
    [query, isInitialShown, isFilterApplied, mergedMetrics]
  );

  const statusSeries = useMemo(() => getStructuredData(mergedMetrics.status), [getStructuredData, mergedMetrics]);
  const emailSeries = useMemo(() => getStructuredData(mergedMetrics.inboxEmails), [getStructuredData, mergedMetrics]);
  const indexesSeries = useMemo(() => getStructuredData(mergedMetrics.indexes), [getStructuredData, mergedMetrics]);
  const relatedDocumentsSeries = useMemo(
    () => getStructuredData(mergedMetrics.relatedDocuments),
    [getStructuredData, mergedMetrics]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack direction="row" py={2} gap={1}>
          <Button
            color={isTimeFilterEq(TimeFilters.WEEK) ? 'primary' : 'secondary'}
            variant={isTimeFilterEq(TimeFilters.WEEK) ? 'contained' : 'outlined'}
            onClick={() => setQuery({ time_filter: isTimeFilterEq(TimeFilters.WEEK) ? '' : 'week' })}
          >
            Last week
          </Button>
          <Button
            color={isTimeFilterEq(TimeFilters.WEEKx2) ? 'primary' : 'secondary'}
            variant={isTimeFilterEq(TimeFilters.WEEKx2) ? 'contained' : 'outlined'}
            onClick={() => setQuery({ time_filter: isTimeFilterEq(TimeFilters.WEEKx2) ? '' : '2weeks' })}
          >
            Last 2 weeks
          </Button>
          <Button
            color={isTimeFilterEq(TimeFilters.WEEKx3) ? 'primary' : 'secondary'}
            variant={isTimeFilterEq(TimeFilters.WEEKx3) ? 'contained' : 'outlined'}
            onClick={() => setQuery({ time_filter: isTimeFilterEq(TimeFilters.WEEKx3) ? '' : '3weeks' })}
          >
            Last 3 weeks
          </Button>

          <FormControl size="small">
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              sx={{ width: 200 }}
              value={query.status}
              onChange={(e) => setQuery({ status: e.target.value })}
            >
              <MenuItem value={undefined}>All statuses</MenuItem>
              <MenuItem value={FrontappLogStatuses.NEW}>{FrontappLogStatuses.NEW}</MenuItem>
              <MenuItem value={FrontappLogStatuses.SUCCEED}>{FrontappLogStatuses.SUCCEED}</MenuItem>
              <MenuItem value={FrontappLogStatuses.FAILED}>{FrontappLogStatuses.FAILED}</MenuItem>
              <MenuItem value={FrontappLogStatuses.NO_DOCS}>{FrontappLogStatuses.NO_DOCS}</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small">
            <InputLabel>Inbox emails</InputLabel>
            <Select
              label="Inbox email"
              sx={{ width: 200 }}
              value={query.inbox_email}
              onChange={(e) => setQuery({ inbox_email: e.target.value })}
            >
              <MenuItem value={undefined}>All emails</MenuItem>
              {keys(initialMetrics.inbox_email).map((inboxEmail) => (
                <MenuItem value={inboxEmail} key={inboxEmail}>
                  {inboxEmail}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Tooltip title="It works only when the filters are applied">
            <FormControlLabel
              sx={{ px: 1 }}
              control={
                <Switch
                  checked={Boolean(hideInitial)}
                  onChange={(e) => setHideInitial(Number(e.target.checked) || undefined)}
                />
              }
              label="Hide initial data"
            />
          </Tooltip>

          {isFilterApplied && (
            <Button sx={{ ml: 2, alignSelf: 'center' }} onClick={resetFilters}>
              Reset filters
            </Button>
          )}
        </Stack>

        <Paper sx={{ p: 2 }}>
          <Typography variant="overline">Counts</Typography>
          {isInitialShown ? (
            <LineChart
              height={300}
              skipAnimation
              series={countsByDay}
              colors={[columnColor, columnColorWithFilter]}
              xAxis={[
                {
                  scaleType: 'band',
                  tickLabelStyle: {
                    angle: -90,
                    textAnchor: 'end',
                    fontSize: 12,
                  },
                  data: Object.keys(mergedMetrics.createdAt),
                  valueFormatter: (x) => format(x, 'dd.MM'),
                },
              ]}
            />
          ) : (
            <BarChart
              height={300}
              skipAnimation
              series={countsByDay}
              colors={[columnColorWithFilter]}
              xAxis={[
                {
                  scaleType: 'band',
                  tickLabelStyle: {
                    angle: -90,
                    textAnchor: 'end',
                    fontSize: 12,
                  },
                  data: Object.keys(metrics.created_at),
                  valueFormatter: (x) => format(x, 'dd.MM'),
                },
              ]}
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="overline">Statuses</Typography>
          <BarChart
            height={300}
            skipAnimation
            borderRadius={3}
            series={statusSeries}
            colors={isInitialShown ? [columnColor, columnColorWithFilter] : [columnColorWithFilter]}
            xAxis={[
              {
                scaleType: 'band',
                data: Object.keys(initialMetrics.status),
              },
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="overline">Inbox emails</Typography>
          <BarChart
            height={300}
            skipAnimation
            borderRadius={3}
            series={emailSeries}
            colors={isInitialShown ? [columnColor, columnColorWithFilter] : [columnColorWithFilter]}
            xAxis={[
              {
                scaleType: 'band',
                data: Object.keys(initialMetrics.inbox_email),
              },
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="overline">Indexes</Typography>
          <BarChart
            height={300}
            skipAnimation
            borderRadius={3}
            series={indexesSeries}
            colors={isInitialShown ? [columnColor, columnColorWithFilter] : [columnColorWithFilter]}
            xAxis={[
              {
                scaleType: 'band',
                data: Object.keys(initialMetrics.indexes),
              },
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="overline">Related documents</Typography>
          <BarChart
            height={300}
            skipAnimation
            borderRadius={3}
            series={relatedDocumentsSeries}
            colors={isInitialShown ? [columnColor, columnColorWithFilter] : [columnColorWithFilter]}
            xAxis={[
              {
                scaleType: 'band',
                data: Object.keys(initialMetrics.related_documents),
              },
            ]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withSuspense(FrontappMetricsRoute);
