import { DeleteOutline, Edit, ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { SidePageLayout } from 'components';
import ConfirmEntityDeleteDialog from 'components/ConfirmEntityDeleteDialog';
import { useModal } from 'hooks';
import { useAuth } from 'lib/auth/AuthContext';
import type { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDeletePrompt, useGetPrompt, useValidatePrompt } from '../api';
import { PromptStatus } from '../types';
import PromptStatusChip from './PromptStatusChip';
import handleEntityDeletionError from 'utils/handleEntityDeletionError';

interface ViewPromptPageProps {
  promptId: string;
}

const ViewPromptPage: FC<ViewPromptPageProps> = ({ promptId }) => {
  const deleteModal = useModal();
  const navigate = useNavigate();
  const { data: prompt } = useGetPrompt({ promptId });

  const { isViewer, isAdmin } = useAuth();

  const { mutate: validate, isPending: isValidating } = useValidatePrompt({ promptId });

  const handleValidate = () => {
    validate();
  };

  const { mutate: deletePrompt, isPending: isDeleting } = useDeletePrompt({
    promptId: prompt.id,
    config: {
      onSuccess: () => {
        navigate('..');
        deleteModal.close();
      },
      onError: handleEntityDeletionError,
    },
  });

  const footerActions = (
    <>
      <LoadingButton
        variant="outlined"
        color="success"
        loading={isValidating}
        onClick={handleValidate}
        disabled={prompt.status === PromptStatus.Verified || !isAdmin}
      >
        Validate
      </LoadingButton>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          variant="outlined"
          disabled={isViewer}
          color="primary"
          component={Link}
          to={`edit?redirect=/prompts/${promptId}`}
          startIcon={<Edit />}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          disabled={isViewer}
          color="error"
          onClick={deleteModal.open}
          startIcon={<DeleteOutline />}
        >
          Delete
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <ConfirmEntityDeleteDialog
        entityName="Prompt"
        entityId={prompt.id}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.close}
        isLoading={isDeleting}
        onDelete={() => deletePrompt()}
      />
      <Box sx={{ flexGrow: 1 }}>
        <SidePageLayout fullHeight title={null} name={prompt.name} footer={footerActions}>
          <Stack spacing={2}>
            <Box>
              <PromptStatusChip status={prompt.status} />
            </Box>
            <>
              <Box>
                <Typography variant="overline">Name</Typography>
                <Paper sx={{ p: 2 }}>{prompt.name}</Paper>
              </Box>
              <Box>
                <Typography variant="overline">Prompt</Typography>
                <Paper sx={{ p: 2 }}>{prompt.content}</Paper>
              </Box>
            </>
            <Box>
              <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0, background: 'transparent' }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 0 }}>
                  <Typography variant="overline">Meta</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Paper sx={{ px: 2, mt: 1 }}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">ID</TableCell>
                          <TableCell>{prompt.id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Created by</TableCell>
                          <TableCell>{prompt.createdBy}</TableCell>
                        </TableRow>
                        {prompt.updatedBy && (
                          <TableRow>
                            <TableCell variant="head">Updated by</TableCell>
                            <TableCell>{prompt.updatedBy}</TableCell>
                          </TableRow>
                        )}
                        {prompt.validatedBy && (
                          <TableRow>
                            <TableCell variant="head">Validated by</TableCell>
                            <TableCell>{prompt.validatedBy}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
        </SidePageLayout>
      </Box>
    </>
  );
};
export default ViewPromptPage;
