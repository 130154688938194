import type { Prompt } from '../types';
import type { PromptDTO} from './types';


export default function getPromptFromDTO(dto: PromptDTO): Prompt {
  const prompt: Prompt = {
    ...dto,
    createdAt: new Date(dto.created_at),
    updatedAt: dto.updated_at ? new Date(dto.updated_at) : null,
    createdBy: dto.created_by,
    updatedBy: dto.updated_by,
    validatedBy: dto.validated_by,
  };

  return prompt;
}