import { type FC, Suspense } from 'react';
import { DrawerPage } from 'components';
import { Box } from '@mui/material';
import { CreateEmbeddingPage } from 'features/embeddings';

const CreateEmbeddingRoute: FC = () => (
  <DrawerPage>
    <Suspense fallback={<Box>Loading skeleton will be addedd</Box>}>
      <CreateEmbeddingPage />
    </Suspense>
  </DrawerPage>
);

export default CreateEmbeddingRoute;
