import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useGetSkeletonArray } from 'hooks';
import type { FC } from 'react';
import { useMemo } from 'react';

interface ListSkeletonProps {
  size?: number;
  titles: { field: string; width?: string | number }[];
}

const ListSkeleton: FC<ListSkeletonProps> = ({ size, titles }) => {
  const items = useGetSkeletonArray(size);
  const columns = useMemo(() => [...titles], [titles]);

  return (
    <>
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table
          stickyHeader
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
          })}
        >
          <TableHead>
            <TableRow>
              {columns.map(({ field, width }, index) => (
                <TableCell key={index} variant="head" component="th" sx={{ backgroundColor: 'white', width }}>
                  {field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ id }) => (
              <TableRow key={id}>
                {columns.map((_, index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={0}
        page={1}
        onPageChange={() => {
          /* do nothing */
        }}
        rowsPerPage={25}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </>
  );
};

export default ListSkeleton;
