import { useSuspenseQuery } from '@tanstack/react-query';
import type { APIListResponse, ListResponse } from 'api';
import { getAPIListResponse, apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import type { Embedding } from '../types';
import queryKeys from './queryKeys';
import type { EmbeddingDTO, EmbeddingsQueryParams } from './types';
import getEmbeddingFromDTO from './getEmbeddingFromDTO';

interface GetEmbeddingsOptions {
  indexPath: string;
  params: EmbeddingsQueryParams;
}

export const getEmbeddings = async (
  { params, indexPath }: GetEmbeddingsOptions,
  signal?: AbortSignal
): Promise<ListResponse<Embedding>> => {
  const res = await apiClient.get<APIListResponse<EmbeddingDTO>>(`/${indexPath}/embeddings`, {
    params,
    signal,
  });

  return getAPIListResponse({
    ...res.data,
    items: res.data.items.map((emb) => getEmbeddingFromDTO(emb)),
  });
};

export type UseGetEmbeddingsQuery = {
  indexPath: string;
  params: EmbeddingsQueryParams;
  config?: QueryConfig<typeof getEmbeddings>;
};

export const useGetEmbeddings = ({ config, indexPath, params }: UseGetEmbeddingsQuery) =>
  useSuspenseQuery({
    ...config,
    queryKey: queryKeys(indexPath).list(params),
    queryFn: ({ signal }) => getEmbeddings({ indexPath, params }, signal),
  });
