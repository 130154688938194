import { apiClient } from 'api';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import type { FrontappLogs } from '../types';
import type { FrontappLogsQueryParams } from './types';
import queryKeys from './queryKeys';

type GetFrontappLogsOptions = {
  params: FrontappLogsQueryParams;
};

export const getFrontappLogs = async (
  { params }: GetFrontappLogsOptions,
  signal?: AbortSignal
): Promise<FrontappLogs> => {
  const res = await apiClient.get(`/frontapp/frontapp-webhook-logs`, { signal, params });
  return res.data;
};

type MutationFnType = () => ReturnType<typeof getFrontappLogs>;

type UseGetFrontappLogsOptions = {
  config?: QueryConfig<MutationFnType>;
  params: FrontappLogsQueryParams;
};

export const useGetFrontappLogs = ({ config, params }: UseGetFrontappLogsOptions) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getFrontappLogs({ params }, signal),
    queryKey: queryKeys().frontAppLogs(params),
  });
};
