import type { FC } from 'react';
import { PageLayout } from 'components';
import { QueryPage } from 'features/queries';

const QNARoute: FC = () => {
  return (
    <PageLayout name="Q&A" outlet>
      <QueryPage />
    </PageLayout>
  );
};

export default QNARoute;
