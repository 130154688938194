import { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useGetFrontappHistory } from 'features/frontapp';
import type { AnswerHistoryEntry } from 'features/queries';

/**
 * Depends on Feature Flag
 */

type UseFrontappHistory = Partial<{
  isFeatureEnabled: boolean;
  history: Array<AnswerHistoryEntry>;
  isLoading: boolean;
}>;

export const useFrontappHistory = (): UseFrontappHistory => {
  const [conversationId] = useQueryParam('conversation_id', StringParam);
  const [lastMessageId] = useQueryParam('last_message_id', StringParam);

  const enabled = Boolean(lastMessageId && conversationId);

  const { data, isPending } = useGetFrontappHistory({
    conversationId: conversationId!,
    lastMessageId: lastMessageId!,
    config: { enabled },
  });

  const history = useMemo(
    () =>
      data?.map(
        (historyItem) =>
          ({
            id: historyItem.id,
            isDraft: historyItem.is_draft,
            type: historyItem.is_inbound ? 'user' : 'ai',
            text: historyItem.text,
          }) as AnswerHistoryEntry
      ),
    [data]
  );

  const isLoading = (() => {
    if (enabled) return isPending;
    return void 0;
  })();

  return useMemo(() => ({ isFeatureEnabled: enabled, history, isLoading }), [enabled, history, isLoading]);
};
