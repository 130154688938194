import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import type { EmbeddingDTO, UpdateEmbeddingPayload } from './types';
import queryKeys from './queryKeys';

type UpdateEmbeddingOptions = {
  indexPath: string;
  embeddingId: string;
  data: UpdateEmbeddingPayload;
};

export const updateEmbedding = async ({
  indexPath,
  embeddingId,
  data,
}: UpdateEmbeddingOptions): Promise<EmbeddingDTO> => {
  const res = await apiClient.patch(`/${indexPath}/embeddings/${embeddingId}`, data);
  return res.data;
};

type MutationFnType = (data: UpdateEmbeddingPayload) => ReturnType<typeof updateEmbedding>;

type UseUpdateEmbeddingOptions = {
  indexPath: string;
  embeddingId: string;
  config?: MutationConfig<MutationFnType>;
};

export const useUpdateEmbedding = ({ indexPath, embeddingId, config }: UseUpdateEmbeddingOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).list() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (data: UpdateEmbeddingPayload) => updateEmbedding({ indexPath, embeddingId, data }),
  });
};
