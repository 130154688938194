import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { SidePageLayout } from 'components';
import { useAuth } from 'lib/auth/AuthContext';
import { type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import type { UpdatePromptPayload } from '../api';
import { promptToDTO, useGetPrompt, useUpdatePrompt } from '../api';
import { promptValidation } from '../validation/promptValidation';
import { StringParam, useQueryParam } from 'use-query-params';
import { PromptInput } from 'components/PromptInput';

interface EditPromptPageProps {
  promptId: string;
}

const EditPromptPage: FC<EditPromptPageProps> = ({ promptId }) => {
  const { isViewer } = useAuth();
  const [redirectUrl] = useQueryParam('redirect', StringParam);

  const promptQuery = useGetPrompt({ promptId });
  const prompt = promptQuery.data!;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<UpdatePromptPayload>({
    mode: 'all',
    values: {
      name: promptToDTO(prompt).name,
      content: promptToDTO(prompt).content,
    },
    resolver: yupResolver(promptValidation),
  });

  const navigate = useNavigate();

  const mutation = useUpdatePrompt({
    promptId,
    config: {
      onSuccess: () => {
        navigate(`../${promptId}`);
      },
    },
  });

  const onSubmit = (data: UpdatePromptPayload) => {
    mutation.mutate(data);
  };

  const createPromptFooter = (
    <>
      <Button variant="text" size="large" component={Link} to={redirectUrl ?? '..'}>
        Cancel
      </Button>
      <LoadingButton
        loading={mutation.isPending}
        disabled={!isValid || isViewer || !isDirty}
        variant="contained"
        size="large"
        sx={{ ml: 'auto' }}
        type="submit"
      >
        Save
      </LoadingButton>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SidePageLayout fullHeight title={null} name="Edit Prompt" footer={createPromptFooter}>
          <Box sx={{ mb: 3 }}>
            <Stack spacing={2}>
              <Typography variant="overline">Name</Typography>
              <TextField
                multiline
                fullWidth
                minRows={1}
                maxRows={1}
                placeholder="Add name"
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                {...register('name')}
              />
            </Stack>
          </Box>
          <Box sx={{ my: 3 }}>
            <Stack spacing={2}>
              <Typography variant="overline">Content</Typography>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <PromptInput
                    {...field}
                    placeholder="Add prompt content"
                    error={Boolean(errors.content)}
                    helperText={errors.content?.message}
                  />
                )}
              />
            </Stack>
          </Box>
        </SidePageLayout>
      </form>
    </Box>
  );
};
export default EditPromptPage;
