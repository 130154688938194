import type { AutocompleteValue, TextFieldProps } from '@mui/material';
import { Autocomplete, ListItem, ListItemText, TextField } from '@mui/material';
import { type ReactNode, useCallback, useMemo } from 'react';
import { useGetSynchronousPrompts } from '../api';
import { PromptStatus, type Prompt } from '../types';
import PromptStatusChip from './PromptStatusChip';
import { useAuth } from 'lib/auth/AuthContext';

type PromptAutocompleteValue<Multiple> = Multiple extends true ? Array<number> : number | null;

interface PromptAutocompleteProps<Multiple extends boolean | undefined> {
  multiple?: Multiple;
  value?: PromptAutocompleteValue<Multiple>;
  onChange: (value: PromptAutocompleteValue<Multiple>) => void;
  InputProps?: TextFieldProps;
}

const PromptAutocomplete = <Multiple extends boolean | undefined>({
  InputProps,
  onChange,
  value,
  multiple,
}: PromptAutocompleteProps<Multiple>): ReactNode => {
  const { isAdmin, isEditor } = useAuth();
  const showNotValidated = isAdmin || isEditor;
  const { data, isPlaceholderData, isPending } = useGetSynchronousPrompts({
    params: { size: 100, offset: 0 },
    config: { placeholderData: { count: 0, items: [] } },
  });

  const prompts = data!.items.filter((p) => (showNotValidated ? true : p.status === PromptStatus.Verified));

  const onAutocompleteChange = useCallback(
    (e: React.SyntheticEvent, val: AutocompleteValue<Prompt, Multiple, false, false>) => {
      if (multiple) {
        const next = (val as Prompt[]).map((d) => d.id);
        next.sort();
        onChange(next as PromptAutocompleteValue<Multiple>);
      } else {
        onChange((val !== null ? (val as Prompt).id : val) as PromptAutocompleteValue<Multiple>);
      }
    },
    [onChange, multiple]
  );

  const derivedValue = useMemo(
    () =>
      (multiple ? prompts!.filter((p) => (value as number[]).includes(p.id)) : prompts!.find((p) => p.id === value)) ??
      null,
    [multiple, prompts, value]
  );

  const isLoading = isPending || isPlaceholderData;

  if (isLoading) {
    return (
      <Autocomplete
        key="loading"
        disabled
        options={[]}
        loading={true}
        renderInput={(params) => <TextField {...params} {...InputProps} disabled />}
      />
    );
  }

  return (
    <Autocomplete
      options={prompts!}
      multiple={multiple}
      fullWidth={InputProps?.fullWidth}
      value={derivedValue as AutocompleteValue<Prompt, Multiple, false, false>}
      onChange={onAutocompleteChange}
      getOptionLabel={(option: Prompt) => option.name}
      disabled={InputProps?.disabled}
      renderInput={(params) => <TextField {...params} {...InputProps} />}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText>
            {option.name}
            <PromptStatusChip status={option.status} size="small" sx={{ ml: 1 }} />
          </ListItemText>
        </ListItem>
      )}
    />
  );
};

export default PromptAutocomplete;
