import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import config from 'config';

const getAuthorizationHeader = async () => {
  const session = await fetchAuthSession();
  const jwt = session.tokens?.idToken?.toString();

  return `Bearer ${jwt}`;
};

const apiClient = axios.create({ baseURL: config.env.ADMIN_API_URL });

apiClient.interceptors.request.use(async (cfg) => {
  cfg.headers.set('Authorization', await getAuthorizationHeader());
  return cfg;
});

export default apiClient;
