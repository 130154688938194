import {
  Alert,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  styled,
  lighten,
  css,
  Tooltip,
  Skeleton,
  Box,
  IconButton,
} from '@mui/material';
import { Replay as ReplayIcon } from '@mui/icons-material';
import type { MouseEvent } from 'react';
import { type FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CombinedForms } from './QueryPage';
import { type AnswerHistoryEntry } from '../types';
import getSkeletonArray from 'utils/getSkeletonArray';
import { rgba } from 'polished';

const GradientMask = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  pointer-events: none;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${rgba(theme.palette.background.default, 0)}, ${
      theme.palette.background.default
    } 90%)`};
`;

const ListItemButtonStyled = styled(ListItemButton)<{ isAnswer: boolean }>`
  opacity: 1;
  border: none;
  overflow: hidden;
  margin-bottom: 8px;
  border-radius: 8px;
  text-overflow: ellipsis;
  background-color: ${({ theme }) => lighten(theme.palette.dark.main, 0.6)};

  &:hover {
    background-color: ${({ theme }) => lighten(theme.palette.dark.main, 0.7)};
  }

  ${({ isAnswer, theme }) =>
    isAnswer &&
    css`
      background-color: ${lighten(theme.palette.dark.main, 0.8)};
      margin-left: 40px;
    `};
`;

const HistoryItem: FC<AnswerHistoryEntry & { onQuestion: (q: string) => void }> = ({
  text,
  type,
  isDraft,
  onQuestion,
}) => {
  const isAnswer = type === 'ai';

  const [expanded, setExpanded] = useState(false);

  const toggle = () => setExpanded(!expanded);

  const handleTextCopy = (e: MouseEvent) => {
    e.stopPropagation();
    onQuestion(text);
  };

  return (
    <Tooltip title={`Click to ${expanded ? 'collapse' : 'expand'}`} enterNextDelay={1000} placement="left">
      <ListItemButtonStyled isAnswer={isAnswer} onClick={toggle}>
        <ListItemText sx={{ width: expanded ? 'auto' : 0 }}>
          <Typography variant="body1" noWrap={!expanded}>
            {!isAnswer && (
              <IconButton sx={{ p: 0, mr: 1 }} size="small" onClick={handleTextCopy}>
                <ReplayIcon />
              </IconButton>
            )}
            {isDraft && <strong>Draft: </strong>}
            {text}
          </Typography>
        </ListItemText>
      </ListItemButtonStyled>
    </Tooltip>
  );
};

const items = getSkeletonArray(6);

type QuestionHistoryListProps = { loading?: boolean; onQuestion: (q: string) => void };

const QuestionHistoryList: FC<QuestionHistoryListProps> = ({ loading, onQuestion }) => {
  const { watch } = useFormContext<CombinedForms>();
  const history = watch('history');

  if (loading) {
    return (
      <Paper>
        <List sx={{ p: 2 }} dense>
          {items.map(({ id }, index) => (
            <Skeleton variant="rounded" height={40} sx={{ mb: 1, ml: index % 2 && 5 }} key={id} />
          ))}
        </List>
      </Paper>
    );
  }

  if (history.length === 0) {
    return (
      <Alert severity="info" variant="outlined">
        Conversation history will appear here. Accept and save your first answer
      </Alert>
    );
  }

  return (
    <Paper sx={{ position: 'relative' }}>
      <Box overflow="auto" maxHeight="calc(100vh - 150px)">
        <List sx={{ p: 2 }} dense>
          {history.map((item) => (
            <HistoryItem key={item.id} {...item} onQuestion={onQuestion} />
          ))}
        </List>
      </Box>
      <GradientMask />
    </Paper>
  );
};

export default QuestionHistoryList;
