import type { FC } from 'react';
import { styled, Box } from '@mui/material';

interface SlicedTextProps {
  text: number | string;
}

const BoxWrapper = styled(Box)`
  --background-color: ${({ theme }) => theme.palette.background.default};
  --text-color: ${({ theme }) => theme.palette.dark.main};

  display: grid;
  user-select: none;
  place-content: center;
  background-color: var(--background-color);
  font-family: 'Oswald', sans-serif;
  font-size: clamp(1.5rem, 1rem + 18vw, 15rem);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-color);
`;

const TopBox = styled(Box)`
  grid-area: 1/1/-1/-1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
`;

const BottomBox = styled(Box)`
  grid-area: 1/1/-1/-1;
  clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
  color: transparent;
  background: -webkit-linear-gradient(177deg, var(--background-color) 53%, var(--text-color) 65%);
  background: linear-gradient(177deg, var(--background-color) 53%, var(--text-color) 65%);
  background-clip: text;
  -webkit-background-clip: text;
  transform: translateX(-0.02em);
`;

const SlicedText: FC<SlicedTextProps> = ({ text }) => (
  <BoxWrapper>
    <TopBox>{text}</TopBox>
    <BottomBox aria-hidden>{text}</BottomBox>
  </BoxWrapper>
);

export default SlicedText;
