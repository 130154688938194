import { type FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryParam, NumberParam } from 'use-query-params';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';

import AnswersBlock from './AnswersBlock';
import QuestionHistoryList from './QuestionHistoryList';
import { queryValidation } from '../validation/queryValidation';
import { PromptAutocomplete } from 'features/prompts';
import { KbSelector } from 'features/embeddings';
import { useFrontappHistory } from 'features/frontapp';
import type { AnswerHistoryEntry, Answer } from '../types';
import { useGenerateAnswer } from '../api';
import { CommaArrayParam } from 'utils/commaArrayParam';

export interface CombinedForms {
  history: AnswerHistoryEntry[];
  useAllEmbeddings: boolean;
  promptId: number;
  indexes: string[];
  question: string;
  answers: Answer[];
}

const QueryPage: FC = () => {
  const { isFeatureEnabled, history, isLoading } = useFrontappHistory();
  const [promptIdDefault] = useQueryParam('prompt_id', NumberParam);
  const [indexesParam] = useQueryParam('indexes', CommaArrayParam);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver<CombinedForms>(queryValidation),
    defaultValues: {
      useAllEmbeddings: false,
      promptId: promptIdDefault!,
      indexes: indexesParam ? (indexesParam as string[]) : [],
      history: [],
      answers: [],
    },
  });

  useEffect(() => {
    /** Populate form data when the feature is enabled */
    if (isFeatureEnabled && history) {
      methods.setValue('history', history);
    }
  }, [isFeatureEnabled, history, methods]);

  const {
    watch,
    control,
    register,
    resetField,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const [promptId, indexes] = watch(['promptId', 'indexes']);
  useEffect(() => resetField('answers'), [promptId, indexes, resetField]);

  const { append: appendAnswer } = useFieldArray({ control, name: 'answers' });
  const generateAnswer = useGenerateAnswer();

  const handleGenerateAnswer = async (data: CombinedForms) => {
    const { promptId, history, question, useAllEmbeddings, indexes } = data;

    const answer = await generateAnswer.mutateAsync({
      useAllEmbeddings,
      indexes,
      question,
      promptId,
      history: history.filter((i) => !i.isDraft),
    });

    appendAnswer(answer);
    resetField('question');
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleGenerateAnswer)}>
        <Grid container spacing={3}>
          <Grid xs={8}>
            <Paper sx={{ p: 2, pt: 1.5 }}>
              <Typography variant="overline">Context</Typography>
              <Box sx={{ mt: 1 }}>
                <Box mb={2}>
                  <Controller
                    name="indexes"
                    control={control}
                    render={({ field, fieldState }) => (
                      <KbSelector
                        multiple
                        fullWidth
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="promptId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PromptAutocomplete
                      multiple={false}
                      InputProps={{
                        label: 'Prompt',
                        ref: field.ref,
                        onBlur: field.onBlur,
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                      }}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </Box>
              <Tooltip
                sx={(t) => ({ color: 'white', backgroundColor: t.palette.primary.main })}
                title="This will include all embeddings in the answer, not just validated"
              >
                <FormControlLabel
                  sx={{ py: 1 }}
                  control={<Switch {...register('useAllEmbeddings')} />}
                  label="Use all embeddings"
                />
              </Tooltip>
            </Paper>
            <Paper sx={{ p: 2, pt: 1.5, mt: 3 }}>
              <Typography variant="overline">Question</Typography>
              <Box sx={{ mt: 1 }}>
                <TextField
                  multiline
                  fullWidth
                  maxRows={6}
                  {...register('question')}
                  placeholder="Question here"
                  error={Boolean(errors.question)}
                  helperText={errors.question?.message}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 3 }}>
                <LoadingButton
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ ml: 'auto' }}
                  disabled={!isValid}
                  loading={generateAnswer.isPending}
                >
                  Generate answer
                </LoadingButton>
              </Box>
            </Paper>
            <Box sx={{ mt: 6 }}>
              <AnswersBlock />
            </Box>
          </Grid>
          <Grid xs={4}>
            <Typography variant="overline">History</Typography>
            <QuestionHistoryList
              loading={isLoading}
              onQuestion={(q) => methods.setValue('question', q, { shouldValidate: true })}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default QueryPage;
