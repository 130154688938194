import type { PaletteColor, PaletteColorOptions, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { lighten } from 'polished';
import config from 'config';

const accentColor = config.env.ENVIRONMENT === 'stage' ? config.theme.accentColorStage : config.theme.accentColor;

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      sidebarWidth: number;
    };
  }

  interface Palette {
    dark: PaletteColor;
  }
  interface PaletteOptions {
    dark: PaletteColorOptions;
  }

  interface ThemeOptions {
    sizes: {
      sidebarWidth: number;
    };
  }
}

export const themeOptions: ThemeOptions = {
  sizes: {
    sidebarWidth: 255,
  },
  palette: {
    mode: 'light',
    dark: {
      main: accentColor,
    },
    primary: {
      main: lighten(0.1, accentColor),
    },
    secondary: {
      main: grey[600],
    },
    info: {
      main: lighten(0.1, accentColor),
    },
    background: {
      default: '#f9fafc',
    },
  },
};

const theme = createTheme(themeOptions);

export const darkSubTheme = createTheme({
  ...themeOptions,
  palette: {
    mode: 'dark',
    dark: {
      main: accentColor,
    },
    primary: {
      main: lighten(0.1, accentColor),
    },
    secondary: {
      main: grey[600],
    },
    info: {
      main: lighten(0.1, accentColor),
    },
    background: {
      paper: accentColor,
    },
  },
});

export default theme;
