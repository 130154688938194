import { LoadingButton, type LoadingButtonProps } from '@mui/lab';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { SidePageLayout } from 'components';
import { type SyntheticEvent, type FC, useState } from 'react';
import { EmbeddingType } from '../types';
import CreateEmbeddingForm from './CreateEmbeddingForm';
import { useOutletContext } from 'react-router';

const CreateEmbeddingPage: FC = () => {
  const outletContext = useOutletContext<null | { listQuery: URLSearchParams }>();
  const listQuery = outletContext?.listQuery;

  const [type, setType] = useState<EmbeddingType>(EmbeddingType.Question);
  const handleChange = (event: SyntheticEvent, nextType: number) => {
    setType(nextType as EmbeddingType);
  };

  const [createButtonProps, setCreateButtonProps] = useState<Omit<LoadingButtonProps, 'children'>>({});

  const createEmbeddingAction = (
    <LoadingButton
      key="create-embedding-button"
      variant="contained"
      size="large"
      sx={{ ml: 'auto' }}
      type="submit"
      {...createButtonProps}
    >
      Create
    </LoadingButton>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SidePageLayout
        fullHeight
        title={null}
        name="Create Embedding"
        footer={createEmbeddingAction}
        backTo={{
          pathname: '..',
          search: listQuery ? listQuery.toString() : '',
        }}
      >
        <Tabs value={type} onChange={handleChange}>
          <Tab value={EmbeddingType.Question} label="Question" />
          <Tab value={EmbeddingType.Text} label="Text" />
        </Tabs>
        <Divider sx={{ mx: -3, mt: '-1px' }} />
        <Box sx={{ mt: 3 }}>
          {(type === EmbeddingType.Question || type === EmbeddingType.Text) && (
            <CreateEmbeddingForm key={type} type={type} setButtonProps={setCreateButtonProps} />
          )}
        </Box>
      </SidePageLayout>
    </Box>
  );
};
export default CreateEmbeddingPage;
