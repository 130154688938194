import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import getEmbeddingFromDTO from './getEmbeddingFromDTO';
import type { EmbeddingDTO } from './types';
import type { Embedding } from '../types';
import queryKeys from './queryKeys';

type GetEmbeddingOptions = {
  indexPath: string;
  embeddingId: string;
};

export const getEmbedding = async (
  { embeddingId, indexPath }: GetEmbeddingOptions,
  signal?: AbortSignal
): Promise<Embedding> => {
  const res = await apiClient.get<EmbeddingDTO>(`/${indexPath}/embeddings/${embeddingId}`, { signal });
  return getEmbeddingFromDTO(res.data);
};

type UseGetEmbeddingOptions = {
  indexPath: string;
  embeddingId: string;
  config?: QueryConfig<typeof getEmbedding>;
};

export const useGetEmbedding = ({ embeddingId, indexPath, config }: UseGetEmbeddingOptions) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getEmbedding({ indexPath, embeddingId }, signal),
    queryKey: queryKeys(indexPath).detail(embeddingId),
  });
};
