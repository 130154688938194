import type { QueryKey } from '@tanstack/react-query';
import type { PromptQueryParams } from './types';

type ID = number | string;

export default () => ({
  list: (meta?: PromptQueryParams) =>
    meta ? (['prompts', 'list', meta] as QueryKey) : (['prompts', 'list'] as QueryKey),
    detail: (promptId: ID) => ['prompts', 'detail', String(promptId)] as QueryKey,
});
