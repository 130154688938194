import { v4 as uuid } from 'uuid';
import copy from 'copy-to-clipboard';
import { Link } from 'react-router-dom';
import { lighten, transparentize } from 'polished';
import { enqueueSnackbar } from 'notistack';
import { useState, type FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft, ChevronRight, ContentCopy, AutorenewOutlined } from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  type SelectChangeEvent,
  Paper,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Link as MuiLink,
  Tooltip,
  Stack,
  Fade,
} from '@mui/material';

import type { CombinedForms } from './QueryPage';
import { CategorySelector, EmbeddingType, KbSelector, useCreateEmbedding } from 'features/embeddings';
import { useAuth } from 'lib/auth/AuthContext';
import { useGenerateAnswer } from '../api';

const AnswersBlock: FC = () => {
  const { isViewer } = useAuth();
  const [appliedIndex, setAppliedIndex] = useState<string>();
  const [isCategorySelecting, setCategorySelecting] = useState(false);
  const { control, watch, resetField, register, setFocus } = useFormContext<CombinedForms>();

  const indexes = watch('indexes');
  const singleIndex = indexes?.length === 1 && indexes[0];

  const { mutateAsync: createEmbedding, isPending: isCreating } = useCreateEmbedding({
    indexPath: singleIndex ? singleIndex : appliedIndex!,
    config: {
      onSuccess: () =>
        enqueueSnackbar('A piece of Knowledge has been created!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }),
    },
  });

  const { isPending: isRegenerating, mutateAsync: regenerate } = useGenerateAnswer();

  const [promptId, answers, history] = watch(['promptId', 'answers', 'history']);
  const hasAnswers = Boolean(answers.length);

  useEffect(() => {
    setAppliedIndex(undefined);
    setCategorySelecting(false);
  }, [promptId, indexes]);

  const { append: appendAnswer } = useFieldArray({ control, name: 'answers' });
  const { append: appendHistory } = useFieldArray({ control, name: 'history' });

  const [answerVariant, setAnswerVariant] = useState(Math.max(0, answers.length - 1));

  useEffect(() => {
    setAnswerVariant(Math.max(0, answers.length - 1));
  }, [answers.length]);

  const handleNextAnswerVariant = () => {
    setAnswerVariant((i) => (i < answers.length - 1 ? (i += 1) : 0));
  };
  const handlePrevAnswerVariant = () => {
    setAnswerVariant((i) => (i > 0 ? (i -= 1) : answers.length - 1));
  };

  const answer = answers[answerVariant];

  const regenerateHandler = async () => {
    const { question, indexes, useAllEmbeddings = false } = answer;

    const answerData = await regenerate({
      useAllEmbeddings,
      indexes,
      question,
      promptId,
      history: history.filter((i) => !i.isDraft),
    });

    appendAnswer(answerData);
  };

  const handleAcceptAnswer = async () => {
    appendHistory({ id: uuid(), type: 'user', text: answer.question });
    appendHistory({ id: uuid(), type: 'ai', text: answer.answer });

    resetField('answers');
    setFocus('question');
  };

  const handleSaveAnswer = async (e: SelectChangeEvent<number | null>) => {
    setCategorySelecting(false);

    const { value } = e.target;
    const category = Number(value);

    appendHistory({ id: uuid(), type: 'user', text: answer.question });
    appendHistory({ id: uuid(), type: 'ai', text: answer.answer });

    await createEmbedding({
      value: {
        category,
        type: EmbeddingType.Question,
        question: answer.question,
        answer: answer.answer,
      },
    });

    resetField('answers');
    setFocus('question');
  };

  const handleCopyAnswer = () => {
    copy(answer.answer);
    enqueueSnackbar('Answer has been copied to clipboard!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  };

  return (
    <Box position="relative">
      {isCategorySelecting && (
        <Fade in>
          <Stack
            p={4}
            zIndex={2}
            alignItems="flex-end"
            justifyContent="center"
            position="absolute"
            sx={(t) => ({
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: 'blur(8px)',
              backgroundColor: transparentize(0.2, t.palette.background.default),
            })}
          >
            <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setCategorySelecting(false)}>
              <CloseIcon />
            </IconButton>
            {!singleIndex && (
              <Stack width="100%" mb={2} direction="row" alignItems="center">
                <Typography textAlign="left" flexGrow={1} mr={2}>
                  Select the relevant knowledge base.
                </Typography>
                <Box minWidth={200}>
                  <KbSelector
                    fullWidth
                    value={appliedIndex}
                    onChange={(e) => setAppliedIndex(e.target.value ?? undefined)}
                  />
                </Box>
              </Stack>
            )}
            <Stack
              width="100%"
              direction="row"
              alignItems="center"
              sx={(t) =>
                !singleIndex
                  ? {
                      // Apply animation after the user selects the KB
                      opacity: appliedIndex ? 1 : 0,
                      transition: t.transitions.create('opacity'),
                    }
                  : null
              }
            >
              <Typography textAlign="left" flexGrow={1} mr={2}>
                Select the category for the embedding that will be created.
              </Typography>
              <Box minWidth={200}>
                <CategorySelector
                  fullWidth
                  disabled={!singleIndex && !appliedIndex}
                  blankOptionText={null}
                  indexPath={singleIndex ? singleIndex : appliedIndex}
                  onChange={handleSaveAnswer}
                />
              </Box>
            </Stack>
          </Stack>
        </Fade>
      )}
      {hasAnswers && (
        <Paper
          elevation={1}
          sx={(theme) => ({
            p: 2,
            pb: 3,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            background: theme.palette.grey[100],
            position: 'relative',
            zIndex: 0,
            mb: -1,
          })}
        >
          <Typography variant="overline" color="text.secondary">
            Question
          </Typography>
          <Typography variant="body1">{answer.question}</Typography>
        </Paper>
      )}
      <Paper sx={{ p: 2, position: 'relative', zIndex: 1 }} elevation={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="overline">Answer</Typography>
          <Box sx={{ ml: 'auto' }}>
            {hasAnswers && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={handlePrevAnswerVariant}>
                  <ChevronLeft />
                </IconButton>
                <Typography variant="body1" color="text.secondary" sx={{ letterSpacing: '0.3' }}>
                  {`${answerVariant + 1}/${answers.length}`}
                </Typography>
                <IconButton onClick={handleNextAnswerVariant}>
                  <ChevronRight />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          {hasAnswers ? (
            answers.map((_, index) => (
              <TextField
                key={index}
                multiline
                fullWidth
                minRows={10}
                maxRows={15}
                placeholder="Nothing to show yet"
                {...register(`answers.${index}.answer`)}
                sx={{ display: answerVariant !== index ? 'none' : '' }}
              />
            ))
          ) : (
            <TextField multiline fullWidth minRows={10} maxRows={15} placeholder="Nothing to show yet" disabled />
          )}
        </Box>
        {Boolean(answer?.references?.length) && (
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 1 }}>
            <Typography variant="body2" sx={{ flexShrink: 0 }}>
              Based on —{' '}
            </Typography>
            {answer.references.map((reference) => (
              <Tooltip
                key={reference.id}
                title={(reference.value.type === EmbeddingType.Question
                  ? reference.value.answer
                  : reference.value.text
                ).slice(0, 200)}
              >
                <MuiLink
                  component={Link}
                  to={`${reference.index_name}/references/${reference.id}`}
                  noWrap
                  sx={(t) => ({
                    px: 0.8,
                    width: '100px',
                    borderRadius: 1,
                    color: t.palette.primary.dark,
                    backgroundColor: lighten(1.05 - Math.pow(reference.score, 1.5), t.palette.primary.light),
                  })}
                >
                  {reference.id}
                </MuiLink>
              </Tooltip>
            ))}
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 3 }}>
          <Button
            variant="text"
            color="inherit"
            startIcon={<ContentCopy />}
            sx={{ color: 'grey' }}
            onClick={handleCopyAnswer}
            disabled={!hasAnswers}
          >
            Copy
          </Button>
          <LoadingButton
            variant="text"
            sx={{ ml: 'auto', color: 'grey' }}
            startIcon={<AutorenewOutlined />}
            onClick={regenerateHandler}
            disabled={!hasAnswers}
            loading={isRegenerating}
          >
            Regenerate
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="primary"
            onClick={handleAcceptAnswer}
            type="button"
            disabled={!hasAnswers || isViewer}
          >
            Accept
          </LoadingButton>
          <LoadingButton
            type="button"
            color="primary"
            variant="contained"
            onClick={() => setCategorySelecting(true)}
            loading={isCreating}
            disabled={!hasAnswers || isCreating || isViewer}
          >
            Save & Accept
          </LoadingButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default AnswersBlock;
