import { type FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { SidePageLayout } from 'components';
import { useNavigate } from 'react-router';
import type { CreatePromptPayload } from '../api';
import { useCreatePrompt } from '../api';
import type { Prompt } from '../types';
import { promptValidation } from '../validation/promptValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from 'lib/auth/AuthContext';
import { PromptInput } from 'components/PromptInput';

const CreatePromptPage: FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreatePromptPayload>({
    mode: 'all',
    defaultValues: {},
    resolver: yupResolver(promptValidation),
  });

  const { isViewer } = useAuth();

  const navigate = useNavigate();
  const mutation = useCreatePrompt({
    config: {
      onSuccess: (data: Prompt) => {
        navigate(`../${data.id}`);
      },
    },
  });

  const onSubmit = (data: CreatePromptPayload) => {
    mutation.mutate(data);
  };

  const createPromptFooter = (
    <LoadingButton
      disabled={!isValid || isViewer}
      loading={mutation.isPending}
      variant="contained"
      size="large"
      sx={{ ml: 'auto' }}
      type="submit"
    >
      Create prompt
    </LoadingButton>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SidePageLayout fullHeight title={null} name="Create Prompt" footer={createPromptFooter}>
          <Box sx={{ mb: 3 }}>
            <Stack spacing={2}>
              <Typography variant="overline">Name</Typography>
              <TextField
                multiline
                fullWidth
                minRows={1}
                maxRows={1}
                placeholder="Add name"
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                {...register('name')}
              />
            </Stack>
          </Box>
          <Box sx={{ my: 3 }}>
            <Stack spacing={2}>
              <Typography variant="overline">Content</Typography>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <PromptInput
                    {...field}
                    placeholder="Add prompt content"
                    error={Boolean(errors.content)}
                    helperText={errors.content?.message}
                  />
                )}
              />
            </Stack>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="overline">This text will be automaticaly added to the end of the prompt:</Typography>

            <Typography variant="caption" component="p">
              You may also take to account messaging history:
              <br />
              {`{chat_history}`}
              <br />
              {`User question: {question}`}
              <br />
              Assistant answer:
            </Typography>
          </Box>
        </SidePageLayout>
      </form>
    </Box>
  );
};
export default CreatePromptPage;
