import { createContext, useContext } from 'react';

export type AuthStateType = 'unauthenticated' | 'authenticated' | 'inProgress';

export interface UserData {
  name: string;
  email: string;
}

export interface AuthStateValue {
  user: UserData | null;
  state: AuthStateType;
  isAdmin: boolean;
  isEditor: boolean;
  isSupport: boolean;
  isViewer: boolean;
}

export type AuthContextType = AuthStateValue;

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return useContext(AuthContext);
}
