import { debounce } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';

export type UseSearchResult = [string, string, (event: ChangeEvent<HTMLInputElement>) => void, () => void];

export function useSearch(path: string = 'search'): UseSearchResult {
  const [search, setSearch] = useQueryParam(path, withDefault(StringParam, ''));
  const [searchFieldValue, setSearchFieldValue] = useState(search);

  useEffect(() => {
    setSearchFieldValue((prevValue) => {
      if (prevValue !== search) {
        return search;
      } else {
        return prevValue;
      }
    });
  }, [search]);

  const debouncedSearch = useMemo(() => debounce(setSearch, 500), [setSearch]);

  const handleSearchFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchFieldValue(event.target.value);

      /*
      We assume that searching with less than 3 characters isn't efficient
      */
      if (event.target.value.length > 2) {
        debouncedSearch(event.target.value);
      } else {
        debouncedSearch(undefined);
      }
    },
    [debouncedSearch]
  );

  const resetSearch = useCallback(() => {
    setSearchFieldValue('');
    setSearch(undefined);
  }, [setSearch]);

  return useMemo(
    () => [search, searchFieldValue, handleSearchFieldChange, resetSearch],
    [search, searchFieldValue, handleSearchFieldChange, resetSearch]
  );
}
