import { useCallback, useState } from 'react';

export function useRowsSelection(idsList: string[]) {
  const [selected, setSelected] = useState<Record<string, boolean | undefined>>({});

  const handleToggle = useCallback((id: string) => {
    setSelected((s) => {
      return { ...s, [id]: !s[id] };
    });
  }, []);

  const handleToggleAll = useCallback(() => {
    setSelected((s) => {
      const total = idsList.length;
      const totalSelected = Object.values(s).reduce((sum, e) => sum + Number(e), 0);
      if (totalSelected < total) {
        return idsList.reduce((s, id) => ({ ...s, [id]: true }), {});
      }
      return {};
    });
  }, [idsList]);

  const clearSelection = useCallback(() => {
    setSelected({});
  }, []);

  const selectedCount = Object.values(selected).reduce((sum, e) => sum + Number(e), 0);
  const ids = Object.entries(selected)
    .filter(([, value]) => value)
    .map(([id]) => id);

  return {
    ids,
    selectedCount,
    selected,
    clearSelection,
    handleToggle,
    handleToggleAll,
  };
}
