import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Typography } from '@mui/material';
import { ModalPageLayout } from 'components';
import { capitalize, lowerFirst } from 'lodash-es';
import type { FC } from 'react';

interface ConfirmEmbeddingDeleteDialogProps {
  entityName: string;
  isOpen: boolean;
  onDelete: () => void;
  onClose: (action?: 'declined') => void;
  isLoading: boolean;
  entityId?: string | number;
  entityIds?: string[] | number[];
}

const ConfirmEntityDeleteDialog: FC<ConfirmEmbeddingDeleteDialogProps> = ({
  entityIds,
  entityId,
  entityName,
  isOpen,
  isLoading,
  onDelete,
  onClose,
}) => {
  const handleClose = () => onClose();

  const multiple = entityIds && entityIds.length > 1;
  const count = multiple ? entityIds.length : 0;

  const name = multiple
    ? `Are you sure you want to delete ${count} ${lowerFirst(entityName)}s?`
    : `Are you sure you want to delete ${capitalize(entityName)} with id ${entityIds ? entityIds[0] : entityId}?`;

  const description = multiple ? (
    <>
      {`All data will be lost. ${count} ${lowerFirst(entityName)}s will be deleted and can't be restored later.`}
      <Typography variant="subtitle2" sx={{ mt: 1 }}>
        Affected ids: {entityIds.join(', ')}
      </Typography>
    </>
  ) : (
    `All data will be lost. The ${capitalize(entityName)} will be deleted and can't be restored later.`
  );

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <ModalPageLayout
        title={null}
        size="medium"
        onClose={handleClose}
        name={name}
        description={description}
        footer={
          <>
            <Button variant="text" onClick={handleClose} color="primary" fullWidth>
              Cancel
            </Button>
            <LoadingButton fullWidth variant="text" color="error" onClick={onDelete} loading={isLoading}>
              {multiple ? `Yes, delete all` : `Yes, delete`}
            </LoadingButton>
          </>
        }
      />
    </Dialog>
  );
};

export default ConfirmEntityDeleteDialog;
