import type { ChipProps, SxProps, Theme } from '@mui/material';
import { Chip, alpha } from '@mui/material';
import { PromptStatus } from '../types';
import type { FC } from 'react';
import mergeSx from 'utils/mergeSx';

const promptStatusToSx: Record<PromptStatus, SxProps<Theme>> = {
  [PromptStatus.Verified]: (theme) => ({
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    color: theme.palette.success.main,
  }),
  [PromptStatus.Unverified]: (theme) => ({
    backgroundColor: alpha(theme.palette.warning.main, 0.2),
    color: theme.palette.warning.main,
  }),
};

const promptStatusToLabel: Record<PromptStatus, string> = {
  [PromptStatus.Verified]: 'Validated',
  [PromptStatus.Unverified]: 'Not Validated',
};

interface PromptStatusChipProps extends ChipProps {
  status: PromptStatus;
  sx?: SxProps;
}

const PromptStatusChip: FC<PromptStatusChipProps> = ({ status, sx = {}, ...rest }) => (
  <Chip label={promptStatusToLabel[status]} sx={mergeSx(promptStatusToSx[status], sx)} {...rest} />
);
export default PromptStatusChip;
