import { Link } from '@mui/material';
import { type FC } from 'react';
import type { LinkProps} from 'react-router-dom';
import { Link as RouterLink, useMatch } from 'react-router-dom';

const HeadLink: FC<LinkProps & { to: string }> = (props) => {
  const isMatched = useMatch(`${props.to}/*`);

  return (
    <Link
      sx={{
        textUnderlineOffset: '0.3em',
        textUnderlineThickness: '0.1em',

        '&.active': {
          textDecoration: 'none',
          color: 'CaptionText',
        },
      }}
      className={isMatched ? 'active' : ''}
      component={RouterLink}
      {...props}
    />
  );
};

export default HeadLink;
