import type { APIListResponse, ListResponse } from '../types';

const getAPIListResponse = <T>(res: APIListResponse<T>): ListResponse<T> => {
  const { items, count } = res;

  return {
    count,
    items,
  };
};

export default getAPIListResponse;
