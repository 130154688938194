import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import type { EmbeddingDTO } from './types';
import queryKeys from './queryKeys';

type ValidateEmbeddingOptions = {
  indexPath: string;
  embeddingId: string;
};

export const validateEmbedding = async ({
  indexPath,
  embeddingId,
}: ValidateEmbeddingOptions): Promise<EmbeddingDTO> => {
  const res = await apiClient.put(`/${indexPath}/embeddings/validated/${embeddingId}`);
  return res.data;
};

type MutationFnType = () => ReturnType<typeof validateEmbedding>;

type UseValidateEmbeddingOptions = {
  indexPath: string;
  embeddingId: string;
  config?: MutationConfig<MutationFnType>;
};

export const useValidateEmbedding = ({ indexPath, embeddingId, config }: UseValidateEmbeddingOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).list() });
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).detail(embeddingId) });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: () => validateEmbedding({ indexPath, embeddingId }),
  });
};
