const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');

    return successful ? Promise.resolve() : Promise.reject();
  } catch (err) {
    return Promise.reject();
  } finally {
    document.body.removeChild(textArea);
  }
};

export const copyTextToClipboard = (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }

  return navigator.clipboard.writeText(text);
};
