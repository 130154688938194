import { Link } from 'react-router-dom';
import { Suspense, type FC, useMemo } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Paper, TextField } from '@mui/material';
import { usePagination, useRouteDataIsolator, useSearch, useSorting } from 'hooks';
import { ListSkeleton, PageLayout, TableListErrorBoundary } from 'components';
import type { EmbeddingsOrderByFields } from 'features/embeddings/api/types';
import PromptsList from 'features/prompts/components/PromptsList';
import { useAuth } from 'lib/auth/AuthContext';

const PromptsListRoute: FC = () => {
  const { isViewer } = useAuth();

  const [search, searchFieldValue, handleSearchFieldChange] = useSearch();
  const sorting = useSorting<EmbeddingsOrderByFields>('created_at', 'desc');
  const pagination = usePagination([search, sorting.field, sorting.order]);

  const { data, listQuery } = useRouteDataIsolator(
    useMemo(
      () => ({
        search,
        sorting,
        pagination,
      }),
      [search, sorting, pagination]
    )
  );

  return (
    <PageLayout
      outlet
      outletContext={{ listQuery }}
      name="Prompts"
      headerActions={
        <Button variant="contained" disabled={isViewer} component={Link} to="add" startIcon={<AddIcon />}>
          Create
        </Button>
      }
    >
      <Box sx={{ py: 2, display: 'flex' }}>
        <TextField
          label="Search"
          placeholder="Search term"
          onChange={handleSearchFieldChange}
          value={searchFieldValue}
        />
      </Box>
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableListErrorBoundary>
          <Suspense
            fallback={
              <ListSkeleton
                titles={[
                  { field: 'ID', width: 50 },
                  { field: 'Name' },
                  { field: 'Prompt', width: 400 },
                  { field: 'Status' },
                  { field: 'Created' },
                  { field: 'Updated' },
                  { field: ' ' },
                ]}
                size={10}
              />
            }
          >
            <PromptsList pagination={data.pagination} sorting={data.sorting} query={data.search} />
          </Suspense>
        </TableListErrorBoundary>
      </Paper>
    </PageLayout>
  );
};

export default PromptsListRoute;
