import type { FC } from 'react';
import { BarChart } from '@mui/x-charts';
import { useGetMetrics } from 'features/embeddings';
import { PageLayout } from 'components';
import useTypedParams from 'utils/useTypedParams';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { formatDistance } from 'date-fns';
import withSuspense from 'utils/withSuspense';

const EmbeddingsMetricsRoute: FC = () => {
  const t = useTheme();
  const { indexPath } = useTypedParams({ indexPath: String });
  const { data: metrics } = useGetMetrics({ indexPath });

  return (
    <PageLayout
      name="Metrics"
      to={`/knowledge-bases/${indexPath}`}
      headerActions={
        <Box px={1} mb={2}>
          <Typography variant="caption" display="block">
            Total documents count: {metrics.total_documents}
          </Typography>
          <Typography variant="caption" display="block">
            First document created: {formatDistance(metrics.first_document_created_at, new Date(), { addSuffix: true })}
          </Typography>
          <Typography variant="caption" display="block">
            Last document created: {formatDistance(metrics.last_document_created_at, new Date(), { addSuffix: true })}
          </Typography>
        </Box>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="overline">Categories</Typography>
            <BarChart
              colors={[t.palette.primary.light]}
              series={[{ data: Object.values(metrics.categories) }]}
              xAxis={[{ scaleType: 'band', data: Object.keys(metrics.categories) }]}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="overline">Types</Typography>
            <BarChart
              colors={[t.palette.primary.light]}
              series={[{ data: Object.values(metrics.types) }]}
              xAxis={[{ scaleType: 'band', data: Object.keys(metrics.types) }]}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="overline">Statuses</Typography>
            <BarChart
              colors={[t.palette.primary.light]}
              series={[{ data: Object.values(metrics.statuses) }]}
              xAxis={[{ scaleType: 'band', data: Object.keys(metrics.statuses) }]}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="overline">Editors</Typography>
            <BarChart
              colors={[t.palette.primary.light]}
              series={[{ data: Object.values(metrics.editors) }]}
              xAxis={[{ scaleType: 'band', data: Object.keys(metrics.editors) }]}
              height={300}
            />
          </Paper>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default withSuspense(EmbeddingsMetricsRoute);
