import { useCallback, useMemo } from 'react';
import { StringParam, useQueryParam, withDefault, createEnumParam } from 'use-query-params';

export type Order = 'asc' | 'desc';

const OrderParam = createEnumParam(['asc', 'desc']);

export interface UseSortingResult<T> {
  field?: string;
  order?: Order;
  createSortHandler: (nextField: T) => () => void;
}

export function useSorting<T extends string>(defaultField?: T, defaultOrder?: Order): UseSortingResult<T> {
  const [field, setField] = useQueryParam('order_by', withDefault(StringParam, defaultField));
  const [order, setOrder] = useQueryParam('order', withDefault(OrderParam, defaultOrder));

  const createSortHandler = useCallback(
    (nextField: T) => () => {
      setField(String(nextField));
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    },
    [order, setField, setOrder]
  );

  return useMemo(() => ({ field, order, createSortHandler }), [field, order, createSortHandler]);
}
