import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { SidePageLayout } from 'components';
import { type FC } from 'react';

const EmbeddingPageSkeleton: FC = () => {
  const footerActions = (
    <>
      <Skeleton width={100} height={36} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Skeleton width={100} height={36} />
        <Skeleton width={100} height={36} />
      </Box>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SidePageLayout fullHeight title={null} name={<Skeleton width={100} />} footer={footerActions}>
        <Stack spacing={2}>
          <Box>
            <Skeleton width={100} />
          </Box>
          <Box>
            <Typography variant="overline">
              <Skeleton width={65} />
            </Typography>
            <Paper sx={{ p: 2 }}>
              <Skeleton width={100} />
            </Paper>
          </Box>
          <Box>
            <Typography variant="overline">
              <Skeleton width={55} />
            </Typography>
            <Paper sx={{ p: 2, whiteSpace: 'pre-line' }}>
              <Skeleton width={100} />
            </Paper>
          </Box>
          <Box>
            <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0, background: 'transparent' }}>
              <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 0 }}>
                <Typography variant="overline">
                  <Skeleton width={35} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Paper sx={{ px: 2, mt: 1 }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head">
                          <Skeleton width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          <Skeleton width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Stack>
      </SidePageLayout>
    </Box>
  );
};
export default EmbeddingPageSkeleton;
