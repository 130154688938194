import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import queryKeys from './queryKeys';
import type { ValidateEmbeddingsInBulkPayload } from './types';

interface ValidateEmbeddingsInBulkOptions {
  indexPath: string;
  data: ValidateEmbeddingsInBulkPayload;
}

export const validateEmbeddingInBulk = ({ indexPath, data }: ValidateEmbeddingsInBulkOptions) =>
  apiClient.post(`/${indexPath}/embeddings/bulk-validate/`, data);

type MutationFnType = (embeddingIds: string[]) => ReturnType<typeof validateEmbeddingInBulk>;

type UseValidateEmbeddingsInBulkOptions = {
  indexPath: string;
  config?: MutationConfig<MutationFnType>;
};

export const useValidateEmbeddingsInBulk = ({ indexPath, config }: UseValidateEmbeddingsInBulkOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).list() });
    },
    mutationFn: (embeddingIds) => validateEmbeddingInBulk({ data: { ids: embeddingIds }, indexPath }),
  });
};
