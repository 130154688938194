import type { QueryKey } from '@tanstack/react-query';
import type { EmbeddingsQueryParams } from './types';

type ID = number | string;

export default (indexPath: string) => ({
  categories: () => ['embeddings', indexPath, 'categories'] as QueryKey,
  metrics: () => ['embeddings', indexPath, 'metrics'] as QueryKey,
  list: (meta?: EmbeddingsQueryParams) =>
    meta ? (['embeddings', indexPath, 'list', meta] as QueryKey) : (['embeddings', indexPath, 'list'] as QueryKey),
  detail: (embeddingId: ID) => ['embeddings', indexPath, 'detail', String(embeddingId)] as QueryKey,
});
