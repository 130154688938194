import { type SxProps, type Theme } from '@mui/material';
import { type SystemStyleObject } from '@mui/system';

export default function mergeSx(own: SxProps<Theme>, passed: SxProps<Theme>): SxProps<Theme> {
  return (theme) => {
    let ownSx: SystemStyleObject<Theme>;
    let passedSx: SystemStyleObject<Theme>;

    if (typeof own === 'function') {
      ownSx = own(theme);
    } else if (Array.isArray(own)) {
      ownSx = own.reduce((sum, one) => mergeSx(sum, one), {});
    } else {
      ownSx = { ...(own as SystemStyleObject<Theme>) };
    }

    if (typeof passed === 'function') {
      passedSx = passed(theme);
    } else if (Array.isArray(passed)) {
      passedSx = passed.reduce((sum, one) => mergeSx(sum, one), {});
    } else {
      passedSx = { ...(passed as SystemStyleObject<Theme>) };
    }

    return {
      ...ownSx,
      ...passedSx,
    };
  };
}
