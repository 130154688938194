import { Amplify } from 'aws-amplify';
import config from 'config';

export type TokenPayload = {
  email: string;
  'cognito:groups': Array<string>;
};

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.env.COGNITO_USER_POOL_ID,
      userPoolClientId: config.env.COGNITO_APP_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: config.env.COGNITO_DOMAIN,
          scopes: ['email', 'openid'],
          redirectSignIn: [config.env.COGNITO_REDIRECT_SIGN_IN ?? `${window.location.origin}/`],
          redirectSignOut: [config.env.COGNITO_REDIRECT_SIGN_OUT ?? `${window.location.origin}/`],
          responseType: 'code',
        },
      },
    },
  },
});
