import { type Prompt } from '../types';
import type { PromptDTO } from './types';


export function promptToDTO(obj: Prompt): PromptDTO {
  const prompt: PromptDTO = {
    ...obj,
    created_at: obj.createdAt.toISOString(),
    updated_at: obj.updatedAt ? obj.updatedAt.toISOString() : null,
    created_by: obj.createdBy,
    updated_by: obj.updatedBy,
    validated_by: obj.validatedBy,
  };

  return prompt;
}
