import {
  Box,
  Drawer,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
  styled,
  List,
  ThemeProvider,
  Skeleton,
} from '@mui/material';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { darkSubTheme } from 'theme';
import SidebarNavItem from '../SidebarNavItem';
import config from 'config';

const HomeLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

interface SidebarSkeletonProps {
  isShown: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const SidebarSkeleton: FC<SidebarSkeletonProps> = ({ isShown, openDrawer, closeDrawer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        pt: 1.3,
      }}
    >
      <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <HomeLink to="/">{config.title}</HomeLink>
      </Toolbar>

      <Box component="nav" sx={{ flexGrow: 1, pt: 1 }}>
        <List>
          <SidebarNavItem to="#" title={<Skeleton />} />
          <SidebarNavItem to="#" title={<Skeleton />} />
          <SidebarNavItem to="#" title={<Skeleton />} />
          <SidebarNavItem to="#" title={<Skeleton />} />
        </List>
      </Box>
      <Box
        sx={{
          py: 3,
        }}
      >
        <List>
          <SidebarNavItem to="#" title={<Skeleton />} />
          <SidebarNavItem to="#" title={<Skeleton />} />
        </List>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <ThemeProvider theme={darkSubTheme}>
        <SwipeableDrawer
          variant="temporary"
          sx={{ zIndex: theme.zIndex.appBar - 1, maxHeight: '100vh' }}
          PaperProps={{
            sx: {
              height: 'unset',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              maxWidth: theme.sizes.sidebarWidth,
              [theme.breakpoints.down('md')]: {
                // top: 65,
                maxWidth: '40%',
              },
              [theme.breakpoints.down('sm')]: {
                // top: 57,
                maxWidth: '100vw',
              },
            },
          }}
          componentsProps={{
            backdrop: () => ({
              sx: {
                [theme.breakpoints.down('md')]: {
                  top: 65,
                },
                [theme.breakpoints.down('sm')]: {
                  top: 57,
                },
              },
            }),
          }}
          open={isShown}
          onOpen={openDrawer}
          onClose={closeDrawer}
        >
          <Toolbar />
          {content}
        </SwipeableDrawer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkSubTheme}>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          width: theme.sizes.sidebarWidth,
        }}
        PaperProps={{
          sx: {
            width: theme.sizes.sidebarWidth,
          },
        }}
      >
        {content}
      </Drawer>
    </ThemeProvider>
  );
};

export default SidebarSkeleton;
