import type { FC } from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectProps,
} from '@mui/material';
import { useGetCategories } from 'features/embeddings';
import { capitalize } from 'lodash-es';
import { useParams } from 'react-router';

type CategorySelectorOwnProps = {
  indexPath?: string;
  blankOptionText?: string | null;
  helperText?: string;
};

const CategorySelector: FC<Omit<SelectProps<number | null>, 'variant'> & CategorySelectorOwnProps> = ({
  blankOptionText = 'All categories',
  helperText,
  indexPath,
  ...props
}) => {
  const { indexPath: indexPathParams } = useParams();
  const withoutBlankOption = blankOptionText === null;
  const { data: categories, isLoading } = useGetCategories({
    indexPath: indexPath ?? indexPathParams!,
    config: { enabled: !props.disabled },
  });

  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel>Category</InputLabel>
      <Select
        label="category"
        placeholder="Category"
        disabled={isLoading}
        endAdornment={
          isLoading && (
            <InputAdornment position="end" sx={{ mr: '-5px' }}>
              <CircularProgress size={20} color="inherit" />
            </InputAdornment>
          )
        }
        {...props}
      >
        {!withoutBlankOption && <MenuItem value={undefined}>{blankOptionText}</MenuItem>}
        {categories?.map(({ id, value }) => (
          <MenuItem key={id} value={id}>
            {capitalize(value.replace(/_/gi, ' '))}
          </MenuItem>
        ))}
      </Select>
      {helperText && props.error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CategorySelector;
