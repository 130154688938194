import { type ReactNode, useCallback } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  type SelectProps,
} from '@mui/material';
import { useGetKnowledgeBases } from 'features/embeddings';
import { capitalize, isArray } from 'lodash-es';

type MultipleBase = boolean | undefined;

type KbSelectorValue<Multiple extends MultipleBase> = Multiple extends true ? string[] : string | null;

type KbSelectorProps<Multiple extends MultipleBase> = Omit<SelectProps<KbSelectorValue<Multiple>>, 'variant'> & {
  multiple?: Multiple;
  helperText?: string;
  emptyLabel?: string | null;
};

const KbSelector = <Multiple extends MultipleBase = false>({
  helperText,
  emptyLabel = null,
  ...props
}: KbSelectorProps<Multiple>) => {
  const { multiple, value } = props;
  const { data: knowledgeBases } = useGetKnowledgeBases();

  const labelText = multiple ? 'Knowledge bases' : 'Knowledge base';

  const renderValue = useCallback<(v: KbSelectorValue<Multiple>) => ReactNode>((value) => {
    if (isArray(value)) {
      return value.map((val) => capitalize(val)).join(', ');
    }

    if (value) {
      return capitalize(value);
    }

    return null;
  }, []);

  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel size={props.size === 'small' ? props.size : undefined}>{labelText}</InputLabel>
      <Select<KbSelectorValue<Multiple>> label={labelText} placeholder={labelText} renderValue={renderValue} {...props}>
        {emptyLabel !== null && <MenuItem value={undefined}>{emptyLabel}</MenuItem>}
        {knowledgeBases?.map((kb) => (
          <MenuItem key={kb} value={kb}>
            {multiple ? (
              <Stack direction="row" alignItems="center">
                <Checkbox
                  sx={{ py: 0.5, pl: 0 }}
                  disabled={props.disabled}
                  checked={(value as KbSelectorValue<true>).includes(kb)}
                />
                {capitalize(kb)}
              </Stack>
            ) : (
              capitalize(kb)
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && props.error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default KbSelector;
