import type { LoadingButtonProps } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, type FC } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import type { CreateEmbeddingPayload } from '../api';
import { useCreateEmbedding } from '../api';
import { embeddingValidation } from '../validation/embeddingsValidation';
import QuestionForm from './QuestionForm';
import TextForm from './TextForm';
import type { Embedding } from '../types';
import { EmbeddingType } from '../types';
import { useAuth } from 'lib/auth/AuthContext';
import { CategorySelector } from '.';
import { Box } from '@mui/material';

interface CreateEmbeddingFormProps {
  type: EmbeddingType;
  setButtonProps: (props: Omit<LoadingButtonProps, 'children'>) => void;
}

const CreateEmbeddingForm: FC<CreateEmbeddingFormProps> = ({ type, setButtonProps }) => {
  const { isViewer } = useAuth();
  const navigate = useNavigate();
  const { indexPath } = useParams();
  const formId = `embedding_${type}_form`;

  const methods = useForm({
    mode: 'all',
    defaultValues: { value: { type: type as EmbeddingType } },
    resolver: yupResolver(embeddingValidation),
  });

  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const category = watch('value.category');

  const { mutate: createEmbedding, isPending } = useCreateEmbedding({
    indexPath: indexPath!,
    config: { onSuccess: (data: Embedding) => navigate(`../${data.id}`) },
  });

  const onSubmit = (data: CreateEmbeddingPayload) => {
    createEmbedding(data);
  };

  useEffect(() => {
    setButtonProps({
      disabled: isViewer || !isValid,
      loading: isPending,
      form: formId,
    });
  }, [setButtonProps, formId, isViewer, isValid, isPending]);

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <Controller
            control={control}
            name="value.category"
            render={({ field, fieldState }) => (
              <CategorySelector
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                blankOptionText={null}
                value={category ?? null}
              />
            )}
          />
        </Box>
        {type === EmbeddingType.Question && <QuestionForm />}
        {type === EmbeddingType.Text && <TextForm />}
      </form>
    </FormProvider>
  );
};

export default CreateEmbeddingForm;
