import * as yup from 'yup';
import type { Answer, AnswerHistoryEntry } from '..';

export const queryValidation = yup
  .object({
    promptId: yup.number().required(),
    indexes: yup.array().of(yup.string().required()).required(),
    useAllEmbeddings: yup.boolean().required(),
    question: yup.string().required('Question is required').min(5, 'The question must be 5 or more characters long'),

    // w/o deep validation, cuz data is injected
    history: yup.array<AnswerHistoryEntry[]>().required(),
    answers: yup.array<Answer[]>().required(),
  })
  .required();
