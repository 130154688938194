import type { FC } from 'react';
import type { UsePaginationResult } from 'hooks';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { LogListRow } from './LogListRow';
import { useGetFrontappLogs } from '../api/getFrontappLogs';
import type { FrontappLogsFilters } from '../api/types';

interface LogsListProps {
  filters: FrontappLogsFilters;
  pagination: UsePaginationResult;
}

const LogsList: FC<LogsListProps> = ({ filters, pagination }) => {
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = pagination;

  const { data } = useGetFrontappLogs({
    params: { offset: page * perPage, size: perPage, ...filters },
  });

  const { items: logs, count: total } = data;

  return (
    <>
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table
          stickyHeader
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
          })}
        >
          <TableHead>
            <TableRow>
              <TableCell variant="head" component="th" sx={{ width: 70, backgroundColor: 'white' }}>
                ID
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Inbox id
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Message id
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Request id
              </TableCell>
              <TableCell variant="head" component="th" sx={{ width: 120, backgroundColor: 'white' }}>
                Convers. id
              </TableCell>

              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Inbox email
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Sender email
              </TableCell>

              <TableCell variant="head" component="th" sx={{ width: 80, backgroundColor: 'white' }}>
                Related doc
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Indexes
              </TableCell>

              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Status
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Created at
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Updated at
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Finished at
              </TableCell>
              <TableCell variant="head" component="th" sx={{ width: 70, backgroundColor: 'white' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((prompt) => (
              <LogListRow key={prompt.id} data={prompt} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </>
  );
};

export default LogsList;
