import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from '@mui/material';
import { rgba } from 'polished';
import { useCallback, type FC } from 'react';
import {
  useGetEmbeddings,
  type EmbeddingsOrderByFields,
  useValidateEmbeddingsInBulk,
  useDeleteEmbeddingsInBulk,
} from '../api';
import EmbeddingListRow from './EmbeddingListRow';
import { useParams } from 'react-router';
import { useRowsSelection } from '../hooks/useRowsSelection';
import { Close, DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from 'lib/auth/AuthContext';
import ConfirmEntityDeleteDialog from 'components/ConfirmEntityDeleteDialog';
import handleDeletionError from 'utils/handleEntityDeletionError';
import { useModal, type UsePaginationResult, type UseSortingResult } from 'hooks';

const BulkActions = styled(Paper)(({ theme }) => ({
  background: rgba(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

interface EmbeddingsListProps {
  query?: string;
  sorting: UseSortingResult<EmbeddingsOrderByFields>;
  pagination: UsePaginationResult;
  category: number | undefined;
  type: number | undefined;
  ids: string | undefined;
}

const EmbeddingsList: FC<EmbeddingsListProps> = ({ ids: idsToFilter, type, pagination, sorting, query, category }) => {
  const { indexPath } = useParams();
  const highlightDefaultSort = Boolean(idsToFilter && !sorting.field);

  const { page, perPage, handlePageChange, handleRowsPerPageChange } = pagination;
  const { field, order, createSortHandler } = sorting;
  const deleteModal = useModal();
  const { isViewer, isSupport } = useAuth();

  const { data: response } = useGetEmbeddings({
    indexPath: indexPath!,
    params: {
      ids: idsToFilter,
      query: query || undefined,
      offset: page * perPage,
      size: perPage,
      order_by: sorting.field as EmbeddingsOrderByFields,
      ascending: sorting.order === 'asc',
      type: type ?? undefined,
      category: category || undefined,
    },
  });
  const { items: embeddings, count: total } = response!;

  const { ids, selected, selectedCount, handleToggle, handleToggleAll, clearSelection } = useRowsSelection(
    embeddings.map((e) => e.id)
  );

  const hasSelection = selectedCount > 0;

  const { mutate: validateEmbeddings, isPending } = useValidateEmbeddingsInBulk({
    indexPath: indexPath!,
    config: {
      onSuccess: () => {
        clearSelection();
      },
    },
  });

  const handleValidate = useCallback(() => {
    validateEmbeddings(ids);
  }, [validateEmbeddings, ids]);

  const { mutate: deleteEmbeddings, isPending: loading } = useDeleteEmbeddingsInBulk({
    indexPath: indexPath!,
    config: {
      onSuccess: () => {
        deleteModal.close();
        clearSelection();
      },
      onError: handleDeletionError,
    },
  });

  const handleDelete = useCallback(() => {
    deleteEmbeddings(ids);
  }, [deleteEmbeddings, ids]);

  return (
    <>
      <ConfirmEntityDeleteDialog
        entityName="Embedding"
        entityIds={ids}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.close}
        isLoading={loading}
        onDelete={handleDelete}
      />
      {hasSelection && (
        <BulkActions>
          <Typography variant="body1">
            <strong>{selectedCount} selected</strong>
          </Typography>
          <Button variant="text" onClick={clearSelection} endIcon={<Close />}>
            Clear selection
          </Button>
          <Box sx={{ display: 'flex', gap: 1, ml: 'auto' }}>
            <LoadingButton
              variant="outlined"
              color="success"
              loading={isPending}
              onClick={handleValidate}
              disabled={isViewer || isSupport}
            >
              Validate selected
            </LoadingButton>
            <Button
              variant="outlined"
              disabled={isViewer}
              color="error"
              onClick={deleteModal.open}
              startIcon={<DeleteOutline />}
            >
              Delete selected
            </Button>
          </Box>
        </BulkActions>
      )}
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table
          stickyHeader
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
          })}
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ width: 50, backgroundColor: 'white' }}>
                <Checkbox
                  color="primary"
                  indeterminate={selectedCount > 0 && embeddings.length > selectedCount}
                  checked={embeddings.length === selectedCount}
                  onChange={handleToggleAll}
                  inputProps={{
                    'aria-label': 'select-all-embeddings',
                  }}
                />
              </TableCell>
              <TableCell variant="head" component="th" sx={{ width: 80, backgroundColor: 'white' }}>
                ID
              </TableCell>
              <TableCell variant="head" component="th" sx={{ width: '45%', backgroundColor: 'white' }}>
                Value
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'category'}
                  direction={field === 'category' ? order : 'asc'}
                  onClick={createSortHandler('category')}
                >
                  Category
                </TableSortLabel>
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'type'}
                  direction={field === 'type' ? order : 'asc'}
                  onClick={createSortHandler('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'status'}
                  direction={field === 'status' ? order : 'asc'}
                  onClick={createSortHandler('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'created_at'}
                  direction={field === 'created_at' ? order : 'asc'}
                  onClick={createSortHandler('created_at')}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'updated_at'}
                  direction={field === 'updated_at' ? order : 'asc'}
                  onClick={createSortHandler('updated_at')}
                >
                  Updated
                </TableSortLabel>
              </TableCell>
              <TableCell variant="head" component="th" sx={{ width: 70, backgroundColor: 'white' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {embeddings.map((embedding, index) => (
              <EmbeddingListRow
                index={index}
                key={embedding.id}
                embedding={embedding}
                highlightDefaultSort={highlightDefaultSort}
                selected={Boolean(selected[embedding.id])}
                handleToggle={handleToggle}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </>
  );
};

export default EmbeddingsList;
