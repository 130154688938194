import { AppBar, Toolbar, Box, IconButton, styled, Divider } from '@mui/material';
import { Menu as BurgerIcon, Close as CloseIcon } from '@mui/icons-material';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import config from 'config';

const NavbarRoot = styled(AppBar)(
  ({ theme: t }) => `
  ${t.breakpoints.up('md')} {
    background-color: ${t.palette.background.paper};
    padding-left: ${t.sizes.sidebarWidth}px;
  }
`
);

const HomeLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  object-fit: contain;
  height: 40px;
`;

interface NavbarProps {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const Navbar: FC<NavbarProps> = ({ isDrawerOpen, openDrawer, closeDrawer }) => {
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          display: 'flex',
        },
        display: 'none',
      })}
    >
      <NavbarRoot position="fixed" elevation={0}>
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
          >
            {isDrawerOpen ? (
              <IconButton key="drawer_opener" onClick={closeDrawer}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton key="drawer_opener" onClick={openDrawer}>
                <BurgerIcon />
              </IconButton>
            )}
            <HomeLink to="/">{config.title}</HomeLink>
          </Box>
        </Toolbar>
        <Divider />
      </NavbarRoot>
    </Box>
  );
};

export default Navbar;
