import { DrawerPage } from 'components';
import { ViewPromptPage, PromptPageSkeleton } from 'features/prompts';
import { type FC, Suspense } from 'react';
import { useParams } from 'react-router';

const ViewPromptRoute: FC = () => {
  const { promptId } = useParams();

  return (
    <DrawerPage>
      <Suspense fallback={<PromptPageSkeleton />}>
        <ViewPromptPage promptId={promptId!} />
      </Suspense>
    </DrawerPage>
  );
};

export default ViewPromptRoute;
