import './amplify';
import { values } from 'lodash-es';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { AuthStateType, UserData } from './AuthContext';
import { AuthContext } from './AuthContext';
import { fetchAuthSession, signInWithRedirect } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useNavigate } from 'react-router';
import { getUserNameByEmail } from 'utils/getUserNameByEmail';
import type { TokenPayload } from './amplify';

enum CognitoGroups {
  Admin = 'admin',
  Support = 'support',
  Editor = 'editor',
  Viewer = 'viewer',
}

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [state, setState] = useState<AuthStateType>('unauthenticated');

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSupport, setIsSupport] = useState<boolean>(false);
  const [isEditor, setIsEditor] = useState<boolean>(false);
  const [isViewer, setIsViewer] = useState<boolean>(false);

  const navigate = useNavigate();

  const propagatePrivileges = (groups: string[] | null) => {
    const hasKnownGroup = values(CognitoGroups).some((group) => groups?.includes(group));

    if (hasKnownGroup) {
      setIsAdmin(groups?.includes(CognitoGroups.Admin) ?? false);
      setIsSupport(groups?.includes(CognitoGroups.Support) ?? false);
      setIsEditor(groups?.includes(CognitoGroups.Editor) ?? false);
      setIsViewer(groups?.includes(CognitoGroups.Viewer) ?? false);
    } else {
      setIsViewer(true);
    }
  };

  const init = useCallback(async () => {
    try {
      const session = await fetchAuthSession();
      const payload = session.tokens?.idToken?.payload as TokenPayload;

      const email = payload.email;
      const name = getUserNameByEmail(email);
      const groups = payload['cognito:groups'] || [];

      setUser({ name, email });
      setState('authenticated');
      propagatePrivileges(groups);
    } catch {
      signInWithRedirect();
    }
  }, []);

  useEffect(() => {
    init();

    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedOut':
        case 'signInWithRedirect_failure':
          setUser(null);
          propagatePrivileges(null);
          break;
        default:
          break;
      }
    });

    return unsubscribe;
  }, [navigate, init]);

  const contextValue = useMemo(
    () => ({
      user,
      state,
      isAdmin,
      isEditor,
      isSupport,
      isViewer,
    }),
    [user, state, isAdmin, isEditor, isSupport, isViewer]
  );

  if (state === 'unauthenticated') {
    return null;
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
