import { v4 as uuid } from 'uuid';
import { Box, TextField, styled, type TextFieldProps } from '@mui/material';
import { lighten } from '@mui/system';
import { useMemo, type FC, useRef } from 'react';

const options = [
  {
    type: 'context',
  },
];

const regexString = `\\{(${options.map((i) => i.type).join('|')})\\}`;
const regexTerm = new RegExp(regexString, 'gi');

const TextFieldStyled = styled(TextField)`
  z-index: 2;
  position: relative;
  background-color: transparent;
`;

const ChunksWrapper = styled(Box)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  padding: 16.5px 14px;
`;

const TextChunk = styled('span')`
  opacity: 0;
`;

const VariableChunk = styled('span')`
  color: transparent;
  border-radius: 4px;
  background-image: ${({ theme }) =>
    `linear-gradient(60deg, ${lighten(theme.palette.primary.main, 0.5)}, ${lighten(theme.palette.primary.main, 0.6)})`};
`;

const RootWrapper = styled(Box)`
  position: relative;

  * {
    font-size: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    line-height: inherit;
    text-align: start;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

type ChunkItem = {
  id: string;
  text: string;
  type: 'variable' | 'text';
};

export const PromptInput: FC<TextFieldProps> = (props) => {
  const value = props.value as string | undefined;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const topLayoutChunks = useMemo<ChunkItem[]>(() => {
    const chunks = value?.split(regexTerm) || [];

    return chunks.map((textChunk) => {
      if (options.some((opt) => opt.type === textChunk)) {
        return {
          id: uuid(),
          text: `{${textChunk}}`,
          type: 'variable',
        };
      } else {
        return {
          id: uuid(),
          text: textChunk,
          type: 'text',
        };
      }
    });
  }, [value]);

  return (
    <>
      <RootWrapper>
        <ChunksWrapper>
          {topLayoutChunks.map(({ id, text, type }) => {
            if (type === 'text') {
              return <TextChunk key={id}>{text}</TextChunk>;
            }

            return <VariableChunk key={id}>{text}</VariableChunk>;
          })}
        </ChunksWrapper>
        <TextFieldStyled inputRef={textareaRef} multiline fullWidth minRows={20} maxRows={25} {...props} />
      </RootWrapper>
    </>
  );
};
