import axios from 'axios';
import { type PropsWithChildren, type FC, useRef, useEffect } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { type FallbackProps, ErrorBoundary } from 'react-error-boundary';
import { CloudOffOutlined, ReplayOutlined } from '@mui/icons-material';
import { Typography, Button, Stack } from '@mui/material';
import { useLocation } from 'react-router';

const TableListErrorComponent: FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
  const location = useLocation();
  const errorLocation = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      // Reset by clicking browser navigation buttons
      resetErrorBoundary();
    }
  }, [location, resetErrorBoundary]);

  return (
    <Stack alignItems="center" p={5}>
      <CloudOffOutlined sx={{ fontSize: 60 }} color="error" />
      <Typography variant="subtitle1" mt={1} color={(t) => t.palette.text.secondary}>
        {axios.isAxiosError(error) ? error.response?.data.error || 'Server error' : error.message}
      </Typography>
      <Button sx={{ mt: 1 }} onClick={resetErrorBoundary} startIcon={<ReplayOutlined />}>
        Try again
      </Button>
    </Stack>
  );
};

const TableListErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary onReset={reset} FallbackComponent={TableListErrorComponent}>
      {children}
    </ErrorBoundary>
  );
};

export default TableListErrorBoundary;
