import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import type { PromptDTO } from './types';
import queryKeys from './queryKeys';

type ValidatePromptOptions = {
  promptId: string;
};

export const validatePrompt = async ({ promptId }: ValidatePromptOptions): Promise<PromptDTO> => {
  const res = await apiClient.put(`/prompt/validate/${promptId}`);
  return res.data;
};

type MutationFnType = () => ReturnType<typeof validatePrompt>;

type UseValidatePromptOptions = {
  promptId: string;
  config?: MutationConfig<MutationFnType>;
};

export const useValidatePrompt = ({ promptId, config }: UseValidatePromptOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys().list() });
      await queryClient.invalidateQueries({ queryKey: queryKeys().detail(promptId) });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: () => validatePrompt({ promptId }),
  });
};
