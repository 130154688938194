import { type FC } from 'react';
import { Navigate } from 'react-router';
import { useGetFrontappConfig } from 'features/frontapp';
import { Typography } from '@mui/material';
import { PageLayout } from 'components';
import withSuspense from 'utils/withSuspense';

const FrontappConfigRoute: FC = () => {
  const { data } = useGetFrontappConfig();
  const inboxId = data[0]?.id;

  if (inboxId) {
    return <Navigate to={`/frontapp/configuration/${inboxId}`} />;
  } else {
    return (
      <PageLayout name="Frontapp config">
        <Typography variant="h6">There`s no created configs</Typography>
      </PageLayout>
    );
  }
};

export default withSuspense(FrontappConfigRoute);
