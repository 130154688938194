import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import { type MutationConfig } from 'lib/react-query';
import queryKeys from './queryKeys';

interface DeletePromptOptions {
  promptId: number | string;
}

export const deletePrompt = ({ promptId }: DeletePromptOptions) => apiClient.delete(`/prompt/${promptId}`);

type MutationFnType = () => ReturnType<typeof deletePrompt>;

type UseDeletePromptOptions = {
  promptId: number | string;
  config?: MutationConfig<MutationFnType>;
};

export const useDeletePrompt = ({ config, promptId }: UseDeletePromptOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys().list() });
      await queryClient.invalidateQueries({ queryKey: queryKeys().detail(promptId) });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: () => deletePrompt({ promptId }),
  });
};
