import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import type { CreateEmbeddingPayload, EmbeddingDTO } from './types';
import queryKeys from './queryKeys';
import getEmbeddingFromDTO from './getEmbeddingFromDTO';
import type { Embedding } from '../types';

type CreateEmbeddingOptions = {
  indexPath: string;
  data: CreateEmbeddingPayload;
};

export const createEmbedding = async ({ data, indexPath }: CreateEmbeddingOptions): Promise<Embedding> => {
  const res = await apiClient.post<EmbeddingDTO>(`/${indexPath}/embeddings`, data);
  return getEmbeddingFromDTO(res.data);
};

type MutationFnType = (data: CreateEmbeddingPayload) => ReturnType<typeof createEmbedding>;

type UseCreateProjectEmbeddingOptions = {
  indexPath: string;
  config?: MutationConfig<MutationFnType>;
};

export const useCreateEmbedding = ({ config, indexPath }: UseCreateProjectEmbeddingOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).list() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (data: CreateEmbeddingPayload) => createEmbedding({ data, indexPath }),
  });
};
