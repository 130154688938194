import type { FC } from 'react';
import { CloudDoneOutlined, Cached, WarningAmber } from '@mui/icons-material';
import { Typography, styled } from '@mui/material';

const CachedAnimated = styled(Cached)`
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export enum LoadingOptions {
  SAVED,
  SAVING,
  ERROR,
}

export const LoadingIcons: FC<{ status: LoadingOptions }> = ({ status }) => {
  switch (status) {
    case LoadingOptions.SAVED:
      return (
        <Typography color="success.main" variant="button" sx={{ display: 'flex', alignItems: 'center' }}>
          <CloudDoneOutlined sx={{ mr: 1 }} fontSize="small" /> Saved
        </Typography>
      );

    case LoadingOptions.SAVING:
      return (
        <Typography color="primary.main" variant="button" sx={{ display: 'flex', alignItems: 'center' }}>
          <CachedAnimated sx={{ mr: 1 }} fontSize="small" /> Saving
        </Typography>
      );

    case LoadingOptions.ERROR:
      return (
        <Typography color="error.main" variant="button" sx={{ display: 'flex', alignItems: 'center' }}>
          <WarningAmber sx={{ mr: 1 }} fontSize="small" /> Config can’t be updated
        </Typography>
      );

    default: {
      return null;
    }
  }
};
