import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { styled } from '@mui/material/styles';
import { useModal } from 'hooks';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const Base = styled('div')`
  display: flex;
  min-height: 100vh;
`;

const Main = styled('main')`
  flex-grow: 1;
  padding: 0 ${({ theme: t }) => t.spacing(3.5)};
  display: flex;
  flex-direction: column;

  ${({ theme: t }) => t.breakpoints.down('md')} {
    &::before {
      content: '';
      display: block;
      ${({ theme: t }) => t.mixins.toolbar};
    }
  }
`;

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const { isOpen: isDrawerOpen, open: openDrawer, close: closeDrawer } = useModal();
  const location = useLocation();

  useEffect(() => {
    closeDrawer();
  }, [closeDrawer, location.pathname]);

  return (
    <Base>
      <Navbar isDrawerOpen={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
      <Sidebar isShown={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
      <Main>{children}</Main>
    </Base>
  );
};
export default AppLayout;
