import type { FC } from 'react';
import { format } from 'date-fns';
import { Box, Chip, IconButton, Link, Stack, TableCell, TableRow, Tooltip, styled } from '@mui/material';
import type { FrontappLogStatuses } from '../types';
import { type FrontappLog } from '../types';
import { StringParam, useQueryParams } from 'use-query-params';
import { ContentCopy, FilterAlt, RemoveRedEye } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { copyTextToClipboard } from 'utils/copyToClipboard';

const ThreeDotBox = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  box-orient: vertical;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface LogListRowProps {
  data: FrontappLog;
}

const FrontappLogStatusChip: FC<{ status: FrontappLogStatuses }> = ({ status }) => {
  return <Chip label={status} />;
};

export const LogListRow: FC<LogListRowProps> = ({ data }) => {
  const [, setQuery] = useQueryParams({
    conversation_id: StringParam,
    message_id: StringParam,
    inbox_email: StringParam,
    sender_email: StringParam,
  });

  return (
    <>
      <TableRow
        hover
        role="link"
        sx={{
          display: 'table-row',
          textDecoration: 'none',
        }}
      >
        <TableCell>{data.id}</TableCell>
        <TableCell>
          <ThreeDotBox>{data.inbox_id}</ThreeDotBox>
        </TableCell>
        <TableCell>
          <Tooltip
            placement="right"
            components={{ Tooltip: Box }}
            title={
              <Stack>
                <IconButton size="small" onClick={() => setQuery({ message_id: data.message_id })}>
                  <FilterAlt fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => copyTextToClipboard(data.message_id)}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Stack>
            }
          >
            <ThreeDotBox>{data.message_id}</ThreeDotBox>
          </Tooltip>
        </TableCell>
        <TableCell>
          <ThreeDotBox>{data.request_id}</ThreeDotBox>
        </TableCell>
        <TableCell>
          <Tooltip
            placement="right"
            components={{ Tooltip: Box }}
            title={
              <Stack>
                <IconButton size="small" onClick={() => setQuery({ conversation_id: data.conversation_id })}>
                  <FilterAlt fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => copyTextToClipboard(data.conversation_id)}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Stack>
            }
          >
            <ThreeDotBox>
              <Link href={`https://app.frontapp.com/open/${data.conversation_id}`} target="_blank">
                {data.conversation_id}
              </Link>
            </ThreeDotBox>
          </Tooltip>
        </TableCell>

        <TableCell>
          <Tooltip
            placement="right"
            components={{ Tooltip: Box }}
            title={
              <Stack>
                <IconButton size="small" onClick={() => setQuery({ inbox_email: data.inbox_email })}>
                  <FilterAlt fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => copyTextToClipboard(data.inbox_email)}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Stack>
            }
          >
            <ThreeDotBox>{data.inbox_email}</ThreeDotBox>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip
            placement="right"
            components={{ Tooltip: Box }}
            title={
              <Stack>
                <IconButton size="small" onClick={() => setQuery({ sender_email: data.sender_email })}>
                  <FilterAlt fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => copyTextToClipboard(data.sender_email)}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Stack>
            }
          >
            <ThreeDotBox>{data.sender_email}</ThreeDotBox>
          </Tooltip>
        </TableCell>

        <TableCell>{data.references_ids?.length ?? 0}</TableCell>
        <TableCell>{data.indexes?.join(', ') ?? '-'}</TableCell>

        <TableCell>
          <FrontappLogStatusChip status={data.status} />
        </TableCell>
        <TableCell>{format(data.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell>{format(data.updated_at, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell>{data.finished_at ? format(data.finished_at, 'dd.MM.yyyy HH:mm') : '-'}</TableCell>
        <TableCell>
          <IconButton component={RouterLink} to={`${data.id}`}>
            <RemoveRedEye />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
