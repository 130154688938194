import env from './env.config';
import project from './project.config.ts';
import type { Config } from './types';

const config: Config = {
  env,
  ...project,
};

export default config;
