import { Stack, Typography } from '@mui/material';
import { PageLayout, HeadLink } from 'components';
import { useMemo, type FC } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useMatch } from 'react-router-dom';

const ContactFormRedirect: FC = () => {
  const isProducts = useMatch('/contact-form/products/*');
  const isCategories = useMatch('/contact-form/categories/*');

  const nameSwitcher = useMemo(() => {
    return (
      <Typography variant="h5" textTransform="capitalize" fontWeight="bold">
        <Stack direction="row" alignItems="center" gap={3}>
          <HeadLink to="/contact-form/products">Products</HeadLink>
          <HeadLink to="/contact-form/categories">Categories</HeadLink>
        </Stack>
      </Typography>
    );
  }, []);

  if (!isProducts && !isCategories) {
    return <Navigate to="/contact-form/products" />;
  }

  return (
    <PageLayout name={nameSwitcher}>
      <Outlet />
    </PageLayout>
  );
};

export default ContactFormRedirect;
