import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';
import { Collapse, ListItemIcon, ListItemText, IconButton, ListItem, Button } from '@mui/material';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import React, { useCallback, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface SidebarNavItemProps {
  to?: string;
  icon?: ReactNode;
  title: ReactNode;
  children?: ReactNode;
  initialExpanded?: boolean;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler;
  disabled?: boolean;
}

const SidebarNavItem: FC<SidebarNavItemProps> = ({
  to,
  icon,
  title,
  children,
  initialExpanded = false,
  sx,
  disabled,
  onClick,
  ...props
}) => {
  const [open, setOpen] = useState(initialExpanded);
  const handleExpand = useCallback(() => {
    setOpen((s) => !s);
  }, [setOpen]);

  const expandable = Boolean(React.Children.toArray(children).length);

  const path = useResolvedPath(to ?? '/this-is-unresolvable-path');
  const match = useMatch({ path: path.pathname, end: expandable, caseSensitive: true });
  const isActive = match != null;
  const component = to ? Link : 'button';
  const handleClick = React.Children.count(children) > 0 ? handleExpand : onClick;

  return (
    <>
      <ListItem sx={sx} {...props}>
        <Button
          variant="text"
          component={component}
          to={to}
          disableElevation
          disableRipple={isActive}
          sx={{
            py: 0.5,
            display: 'flex',
            flexGrow: 1,
            textTransform: 'none',
            textAlign: 'left',
            color: '#fff',
            ...(isActive ? { background: 'rgba(144,202,249,0.08)' } : {}),
          }}
          onClick={handleClick}
          disabled={disabled}
        >
          <ListItemIcon sx={{ minWidth: 32, opacity: 0.56 }}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </Button>

        {expandable && <IconButton onClick={handleExpand}>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
      </ListItem>
      {expandable && (
        <Collapse in={open} timeout="auto" sx={{ '& .MuiListItemIcon-root': { ml: 2.5 } }}>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default SidebarNavItem;
