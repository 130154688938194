import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import queryKeys from './queryKeys';

interface DeleteEmbeddingOptions {
  indexPath: string;
  embeddingId: number | string;
}

export const deleteEmbedding = ({ indexPath, embeddingId }: DeleteEmbeddingOptions) =>
  apiClient.delete(`/${indexPath}/embeddings/${embeddingId}`);

type MutationFnType = () => ReturnType<typeof deleteEmbedding>;

type UseDeleteEmbeddingOptions = {
  indexPath: string;
  embeddingId: number | string;
  config?: MutationConfig<MutationFnType>;
};

export const useDeleteEmbedding = ({ indexPath, config, embeddingId }: UseDeleteEmbeddingOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).list() });
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).detail(embeddingId) });
    },
    mutationFn: () => deleteEmbedding({ embeddingId, indexPath }),
  });
};
