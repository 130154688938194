import type { QueryKey } from '@tanstack/react-query';
import type { FrontappLogsQueryParams } from './types';

export default () => ({
  frontAppConfig: () => ['frontAppConfig'] as QueryKey,
  frontAppLogs: (meta: FrontappLogsQueryParams) => (meta ? ['frontAppLogs', meta] : (['frontAppLogs'] as QueryKey)),
  frontAppLog: (logId: number) => ['frontAppLogs', logId] as QueryKey,
  frontAppMetrics: (meta: any) => (meta ? ['frontAppMetrics', meta] : (['frontAppMetrics'] as QueryKey)),
  frontAppHistory: (conversationId: string, lastMessageId: string) =>
    ['frontAppHistory', conversationId, lastMessageId] as QueryKey,
});
