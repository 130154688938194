import { apiClient } from 'api';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import type { FrontappLogSingle } from '../types';
import queryKeys from './queryKeys';

type GetFrontappLogOptions = {
  logId: number;
};

export const getFrontappLog = async (
  { logId }: GetFrontappLogOptions,
  signal?: AbortSignal
): Promise<FrontappLogSingle> => {
  const res = await apiClient.get(`/frontapp/frontapp-webhook-logs/${logId}`, { signal });
  return res.data;
};

type MutationFnType = () => ReturnType<typeof getFrontappLog>;

type UseGetFrontappLogOptions = {
  logId: number;
  config?: QueryConfig<MutationFnType>;
};

export const useGetFrontappLog = ({ config, logId }: UseGetFrontappLogOptions) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getFrontappLog({ logId }, signal),
    queryKey: queryKeys().frontAppLog(logId),
  });
};
