import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import getPromptFromDTO from './getPromptsFromDTO';
import type { PromptDTO } from './types';
import type { Prompt } from '../types';
import queryKeys from './queryKeys';

type GetPromptOptions = {
  promptId: string;
};

export const getPrompt = async ({ promptId }: GetPromptOptions, signal?: AbortSignal): Promise<Prompt> => {
  const res = await apiClient.get<PromptDTO>(`/prompt/${promptId}`, { signal });
  return getPromptFromDTO(res.data);
};

type UseGetPromptOptions = {
  promptId: string;
  config?: QueryConfig<typeof getPrompt>;
};

export const useGetPrompt = ({ promptId, config }: UseGetPromptOptions) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getPrompt({ promptId }, signal),
    queryKey: queryKeys().detail(promptId),
  });
};
