import { apiClient } from 'api';
import queryKeys from './queryKeys';
import type { QueryConfig } from 'lib/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type { FrontappMetricsQueryParams } from './types';
import type { FrontappMetric } from '../types';

export const getFrontappMetrics = async (params?: FrontappMetricsQueryParams, signal?: AbortSignal) => {
  const { data } = await apiClient.get<FrontappMetric>(`/frontapp/frontapp-webhook-logs/metrics`, { signal, params });
  return data;
};

type MutationFnType = () => ReturnType<typeof getFrontappMetrics>;

type UseGetFrontappMetricsOptions = {
  config?: QueryConfig<MutationFnType>;
  params?: FrontappMetricsQueryParams;
};

export const useGetSyncFrontappMetrics = ({ params, config }: UseGetFrontappMetricsOptions = {}) => {
  return useQuery({
    retry: false,
    ...config,
    initialData: {
      status: {},
      created_at: {},
      inbox_email: {},
      indexes: {},
      related_documents: {},
    },
    queryFn: ({ signal }) => getFrontappMetrics(params, signal),
    queryKey: queryKeys().frontAppMetrics(params),
  });
};

export const useGetFrontappMetrics = ({ params, config }: UseGetFrontappMetricsOptions = {}) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getFrontappMetrics(params, signal),
    queryKey: queryKeys().frontAppMetrics(params),
  });
};
