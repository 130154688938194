import { type FC, Suspense } from 'react';
import { DrawerPage } from 'components';
import { Box } from '@mui/material';
import { EditEmbeddingPage } from 'features/embeddings';
import { useParams } from 'react-router';

const EditEmbeddingRoute: FC = () => {
  const { embeddingId } = useParams();
  return (
    <DrawerPage>
      <Suspense fallback={<Box>Loading skeleton will be addedd</Box>}>
        <EditEmbeddingPage embeddingId={embeddingId!} />
      </Suspense>
    </DrawerPage>
  );
};

export default EditEmbeddingRoute;
