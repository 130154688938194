import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import type { PromptDTO, UpdatePromptPayload } from './types';
import queryKeys from './queryKeys';

type UpdatePromptOptions = {
  promptId: string;
  data: UpdatePromptPayload;
};

export const updatePrompt = async ({ promptId, data }: UpdatePromptOptions): Promise<PromptDTO> => {
  const update = Object.fromEntries(Object.entries(data).filter(([, v]) => v != null));
  const res = await apiClient.patch(`/prompt/${promptId}`, update);
  return res.data;
};

type MutationFnType = (data: UpdatePromptPayload) => ReturnType<typeof updatePrompt>;

type UseUpdatePromptOptions = {
  promptId: string;
  config?: MutationConfig<MutationFnType>;
};

export const useUpdatePrompt = ({ promptId, config }: UseUpdatePromptOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys().list() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (data: UpdatePromptPayload) => updatePrompt({ promptId, data }),
  });
};
