import { format } from 'date-fns';
import { useOutletContext } from 'react-router';
import { useState, type FC, useEffect } from 'react';
import { Box, Button, Paper, Table, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import useTypedParams from 'utils/useTypedParams';
import { SidePageLayout } from 'components';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { FrontappLogStatuses } from '../types';
import { useGetFrontappLog } from '../api';
import { Link } from 'react-router-dom';

interface LogViewProps {}

const LogView: FC<LogViewProps> = () => {
  const outletContext = useOutletContext<null | { listQuery: URLSearchParams }>();
  const listQuery = outletContext?.listQuery;

  const [openPayload, setOpenPayload] = useState(false);
  const [openTraceback, setOpenTraceback] = useState(false);

  const { logId } = useTypedParams({ logId: Number });
  const { data } = useGetFrontappLog({ logId });

  useEffect(() => {
    if (data.status === FrontappLogStatuses.FAILED && data.traceback) {
      setOpenTraceback(true);
    }
  }, [data]);

  return (
    <SidePageLayout
      fullHeight
      title={null}
      name={data.id}
      backTo={{
        pathname: '..',
        search: listQuery ? listQuery.toString() : '',
      }}
    >
      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="simple table"
          sx={(theme) => ({
            minWidth: 650,
            [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
          })}
        >
          {data.payload && (
            <>
              <TableRow>
                <TableCell variant="head">Payload</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    onClick={() => setOpenPayload(!openPayload)}
                    endIcon={openPayload ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  >
                    Expand
                  </Button>
                </TableCell>
              </TableRow>
              {openPayload && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={2}>
                    <Box sx={{ backgroundColor: 'ThreeDFace', overflow: 'auto', maxWidth: '100%' }} padding={2}>
                      <pre>{JSON.stringify(data.payload, null, 2)}</pre>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
          {data.traceback && (
            <>
              <TableRow>
                <TableCell variant="head">Traceback</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    onClick={() => setOpenTraceback(!openTraceback)}
                    endIcon={openTraceback ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  >
                    Expand
                  </Button>
                </TableCell>
              </TableRow>
              {openTraceback && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={2}>
                    <TextField
                      fullWidth
                      InputProps={{ readOnly: true, disableUnderline: true, sx: { padding: 2, borderRadius: 0 } }}
                      multiline
                      defaultValue={data.traceback}
                      variant="filled"
                    />
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
          <TableRow>
            <TableCell variant="head">Id</TableCell>
            <TableCell>{data.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Inbox Id</TableCell>
            <TableCell>{data.inbox_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Message Id</TableCell>
            <TableCell>{data.message_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Request Id</TableCell>
            <TableCell>{data.request_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Conversation Id</TableCell>
            <TableCell>{data.conversation_id}</TableCell>
          </TableRow>
          {data.question && (
            <TableRow>
              <TableCell variant="head">Question</TableCell>
              <TableCell>{data.question}</TableCell>
            </TableRow>
          )}
          {!!data.indexes?.length && (
            <TableRow>
              <TableCell variant="head">Indexes</TableCell>
              <TableCell>{data.indexes.join('\n')}</TableCell>
            </TableRow>
          )}
          {!!(data.references_ids?.length && data.indexes?.length) && (
            <TableRow>
              <TableCell variant="head">References ids</TableCell>
              <TableCell>
                {data.references_ids.map((id) => (
                  <Box key={id}>
                    <Typography
                      color="primary"
                      variant="body2"
                      component={Link}
                      to={`/knowledge-bases/${data.indexes![0]}/${id}`}
                    >
                      {id}
                    </Typography>
                    <br />
                  </Box>
                ))}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell variant="head">Inbox email</TableCell>
            <TableCell>{data.inbox_email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Sender email</TableCell>
            <TableCell>{data.sender_email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Status</TableCell>
            <TableCell>{data.status}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Created at</TableCell>
            <TableCell>{format(data.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Updated at</TableCell>
            <TableCell>{format(data.updated_at, 'dd.MM.yyyy HH:mm')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Finished at</TableCell>
            <TableCell>{data.finished_at ? format(data.finished_at, 'dd.MM.yyyy HH:mm') : '-'}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </SidePageLayout>
  );
};

export default LogView;
