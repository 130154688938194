import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { MutationConfig } from 'lib/react-query';
import queryKeys from './queryKeys';
import type { DeleteEmbeddingsInBulkPayload } from './types';

interface DeleteEmbeddingsInBulkOptions {
  indexPath: string;
  data: DeleteEmbeddingsInBulkPayload;
}

export const deleteEmbeddingInBulk = ({ indexPath, data }: DeleteEmbeddingsInBulkOptions) =>
  apiClient.post(`/${indexPath}/embeddings/bulk-delete/`, data);

type MutationFnType = (embeddingIds: string[]) => ReturnType<typeof deleteEmbeddingInBulk>;

type UseDeleteEmbeddingInBulkOptions = {
  indexPath: string;
  config?: MutationConfig<MutationFnType>;
};

export const useDeleteEmbeddingsInBulk = ({ indexPath, config }: UseDeleteEmbeddingInBulkOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      await queryClient.invalidateQueries({ queryKey: queryKeys(indexPath).list() });
    },
    mutationFn: (embeddingIds) => deleteEmbeddingInBulk({ data: { ids: embeddingIds }, indexPath }),
  });
};
