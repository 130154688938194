import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import type { UsePaginationResult, UseSortingResult } from 'hooks';
import type { FC } from 'react';
import { useGetPrompts, type PromptOrderByFields } from '../api';
import PromptListRow from './PromptListRow';

interface PromptsListProps {
  sorting: UseSortingResult<PromptOrderByFields>;
  pagination: UsePaginationResult;
  query?: string;
}

const PromptsList: FC<PromptsListProps> = ({ pagination, sorting, query }) => {
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = pagination;
  const { field, order, createSortHandler } = sorting;

  const { data: response } = useGetPrompts({
    params: {
      query: query || undefined,
      offset: page * perPage,
      size: perPage,
      order_by: sorting.field as PromptOrderByFields,
      ascending: sorting.order === 'asc',
    },
  });
  const { items: prompts, count: total } = response!;

  return (
    <>
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table
          stickyHeader
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
          })}
        >
          <TableHead>
            <TableRow>
              <TableCell variant="head" component="th" sx={{ width: 50, backgroundColor: 'white' }}>
                ID
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Name
              </TableCell>
              <TableCell variant="head" component="th" sx={{ width: 400, backgroundColor: 'white' }}>
                Prompt
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'status'}
                  direction={field === 'status' ? order : 'asc'}
                  onClick={createSortHandler('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'created_at'}
                  direction={field === 'created_at' ? order : 'asc'}
                  onClick={createSortHandler('created_at')}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                Updated
              </TableCell>
              <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {prompts.map((prompt) => (
              <PromptListRow key={prompt.id} prompt={prompt} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </>
  );
};

export default PromptsList;
