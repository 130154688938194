import * as yup from 'yup';

export const promptValidation = yup.object().shape({
    name: yup.string().required('Name is required').min(3, 'The string must be 3 or more characters long'),
    content: yup.string().required('Content is required').min(10, 'The string must be more than 10 characters long')
    .test('contains-context', 'The string must contain the {context} keyword', (value) => {
      if (value && typeof value === 'string') {
        return value.includes(`{context}`); 
      }
      return false;
    })
});