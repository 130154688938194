import type { FC } from 'react';
import { isAxiosError } from 'axios';
import { signOut } from 'aws-amplify/auth';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ArrowBackIosNew, SpaceDashboard } from '@mui/icons-material';
import { Box, Link, Button, Fade, Stack, Typography, styled } from '@mui/material';
import type { FallbackProps } from 'react-error-boundary';
import SlicedText from './SlicedText';
import config from 'config';

const MainWrapper = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type AxiosErrorResponseData = { message?: string; error?: string };

const GlobalErrorBoundaryFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const errorLocation = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      // Reset by clicking browser navigation buttons
      resetErrorBoundary();
    }
  }, [location, resetErrorBoundary]);

  const doesAnyHistoryEntryExist = location.key !== 'default';

  const errorData = useMemo(() => {
    if (isAxiosError<AxiosErrorResponseData>(error)) {
      const { response } = error;
      const statusCode = response?.status ?? 500;
      const errorMessage = response?.data?.message ?? response?.data?.error ?? 'Server error';

      return {
        statusCode,
        mesage: errorMessage,
      };
    }

    if (process.env.NODE_ENV === 'development') {
      throw error;
    } else {
      return {
        statusCode: 500,
        mesage: 'Server error :(',
      };
    }
  }, [error]);

  const handleReset = () => {
    resetErrorBoundary();
  };

  const goBack = () => {
    navigate(-1);
    resetErrorBoundary();
  };

  if (errorData.statusCode === 401) {
    signOut();
    return null;
  }

  if (errorData.statusCode === 403) {
    return (
      <MainWrapper>
        <Fade in timeout={500}>
          <Box mt={-10} textAlign="center">
            <Typography maxWidth={500} variant="h6" component="h1">
              We`re glad to see you here. Don`t hesitate to contact the{' '}
              <Link target="_blank" rel="noreferrer" href="https://welltech.freshservice.com/support/catalog/items/75">
                IT Help Desk
              </Link>{' '}
              to attain access to the {config.title}.
            </Typography>
          </Box>
        </Fade>
      </MainWrapper>
    );
  }

  return (
    <MainWrapper>
      <Fade in timeout={500}>
        <Box mt={-10} textAlign="center">
          <Typography mb={-5} variant="h5" component="h1">
            {errorData.mesage}
          </Typography>
          <SlicedText text={errorData.statusCode} />
          <Stack mt={-5} gap={2} flexDirection="row" justifyContent="center">
            {doesAnyHistoryEntryExist ? (
              <Button startIcon={<ArrowBackIosNew />} variant="outlined" size="large" onClick={goBack}>
                Go back
              </Button>
            ) : (
              <Button startIcon={<SpaceDashboard />} variant="outlined" size="large" onClick={() => navigate('/')}>
                Home
              </Button>
            )}
            <Button variant="contained" size="large" onClick={handleReset}>
              Reload page
            </Button>
          </Stack>
        </Box>
      </Fade>
    </MainWrapper>
  );
};

export default GlobalErrorBoundaryFallback;
