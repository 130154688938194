import { type FC } from 'react';
import { useGetKnowledgeBases } from 'features/embeddings';
import { Navigate } from 'react-router';

const EmbeddingsListRoute: FC = () => {
  const { data } = useGetKnowledgeBases();

  return <Navigate to={`/knowledge-bases/${data[0]}`} />;
};

export default EmbeddingsListRoute;
