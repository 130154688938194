import { DrawerPage } from 'components';
import LogView from 'features/frontapp/components/LogView';
import { type FC, Suspense } from 'react';

const FrontappLogViewRoute: FC = () => {
  return (
    <DrawerPage halfSize>
      <Suspense>
        <LogView />
      </Suspense>
    </DrawerPage>
  );
};

export default FrontappLogViewRoute;
