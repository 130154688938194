import type { FC } from 'react';
import { AppBar, Toolbar, Box, IconButton, styled, useTheme, useMediaQuery } from '@mui/material';
import { Menu as BurgerIcon, Close as CloseIcon } from '@mui/icons-material';

const NavbarRoot = styled(AppBar)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding-left: ${({ theme }) => theme.sizes.sidebarWidth}px;
  }
`;

interface NavbarSkeletonProps {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const NavbarSkeleton: FC<NavbarSkeletonProps> = ({ isDrawerOpen, openDrawer, closeDrawer }) => {
  const defaultTheme = useTheme();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('md'));

  return isMobile ? (
    <NavbarRoot position="fixed" elevation={0}>
      <Toolbar>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
            display: 'none',
          })}
        >
          {isDrawerOpen ? (
            <IconButton key="drawer_opener" onClick={closeDrawer}>
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton key="drawer_opener" onClick={openDrawer}>
              <BurgerIcon />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </NavbarRoot>
  ) : null;
};

export default NavbarSkeleton;
