import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';

export const getKnowledgeBases = async (signal?: AbortSignal) => {
  const res = await apiClient.get<string[]>(`/knowledge-bases`, { signal });
  return res.data;
};

type UseGetKnowledgeBasesOptions = {
  config?: QueryConfig<typeof getKnowledgeBases>;
};

export const useGetKnowledgeBases = ({ config }: UseGetKnowledgeBasesOptions = {}) => {
  return useSuspenseQuery({
    retry: false,
    staleTime: 60 * 1000,
    ...config,
    queryFn: ({ signal }) => getKnowledgeBases(signal),
    queryKey: ['knowledge-base', 'list'],
  });
};
