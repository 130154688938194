import * as yup from 'yup';
import { EmbeddingType } from '../types';

const textEmbeddingSchema = yup.object({
  type: yup.number().oneOf([EmbeddingType.Text]).required('Type is required'),
  category: yup.number().required('Category is a required field'),
  text: yup.string().required('Text is required').min(10, 'The text must be more than 10 characters long'),
});

const questionEmbeddingSchema = yup.object({
  type: yup.number().oneOf([EmbeddingType.Question]).required('Type is required'),
  category: yup.number().required('Category is a required field'),
  question: yup.string().required('Question is required').min(5, 'The question must be 5 or more characters long'),
  answer: yup.string().required('Answer is required').min(10, 'The answer must be more than 10 characters long'),
});

const embeddingValueSchema = yup.lazy((value) => {
  switch (value?.type) {
    case EmbeddingType.Text:
      return textEmbeddingSchema.required();
    case EmbeddingType.Question:
      return questionEmbeddingSchema.required();
    default:
      throw new TypeError('Unknown embedding type');
  }
});

export const embeddingValidation = yup.object({
  value: embeddingValueSchema,
});
