import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Stack, TextField, Typography } from '@mui/material';
import type { CreateEmbeddingPayload, TextEmbeddingPayload } from '../api';

const TextForm: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateEmbeddingPayload<TextEmbeddingPayload>>();

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="overline">Text</Typography>
        <TextField
          multiline
          fullWidth
          minRows={25}
          maxRows={30}
          placeholder="Paste or type your knowledge piece here"
          error={Boolean(errors.value?.text)}
          helperText={errors.value?.text?.message}
          {...register('value.text', { shouldUnregister: true })}
        />
      </Box>
    </Stack>
  );
};

export default TextForm;
