import type { FC } from 'react';
import { isString } from 'lodash-es';
import { Titled } from 'react-titled';
import { ArrowBackIosNew as CloseIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Outlet, type To } from 'react-router';
import { Link } from 'react-router-dom';

interface PageLayoutProps {
  to?: To;
  children: React.ReactNode;
  headerActions?: React.ReactNode;
  title?: string | null;
  outlet?: boolean;
  outletContext?: unknown;
  name: React.ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({
  to,
  children,
  headerActions = null,
  outlet = false,
  outletContext = null,
  name,
  title,
}) => {
  const pageTitle = (() => {
    if (isString(title)) return title;
    /** Fallback value */
    if (isString(name)) return name;
    return '';
  })();

  return (
    <>
      {title !== null && <Titled title={(rest) => `${pageTitle} - ${rest}`} />}

      <Stack pt={3} flexGrow={1}>
        <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', minHeight: '38px' }}>
          <Stack direction="row" alignItems="center">
            {to && (
              <IconButton sx={{ mr: 2 }} component={Link} to={to}>
                <CloseIcon />
              </IconButton>
            )}
            <Typography variant="h5" textTransform="capitalize" fontWeight={isString(name) ? 'bold' : ''}>
              {name}
            </Typography>
          </Stack>
          {headerActions}
        </Box>
        <Box sx={{ pt: 3, flexGrow: 1 }}>
          {children}
          <Box position="relative">{outlet && <Outlet context={outletContext} />}</Box>
        </Box>
      </Stack>
    </>
  );
};

export default PageLayout;
