import { isUndefined } from 'lodash-es';
import { useParams } from 'react-router';
import objectTypedKeys from './objectTypedKeys';

type ConstructorToPrimitive<T> = T extends NumberConstructor
  ? number
  : T extends StringConstructor
    ? string
    : T extends BooleanConstructor
      ? boolean
      : undefined;

type AvaliableTypes = NumberConstructor | StringConstructor | BooleanConstructor;

type ModifyRecordValue<T> = {
  [K in keyof T]: ConstructorToPrimitive<T[K]>;
};

const useTypedParams = <T extends Record<string, AvaliableTypes>>(configuration: T) => {
  const params = useParams<Record<string, string>>();

  const result: Partial<ModifyRecordValue<T>> = {};

  objectTypedKeys(configuration).forEach((dynamicPath) => {
    const value = params[dynamicPath] as string | undefined;

    if (isUndefined(value)) {
      return;
    }

    const constructor = configuration[dynamicPath];
    const convertedValue = constructor(value) as ConstructorToPrimitive<T[keyof T]>;

    if (!isUndefined(convertedValue)) {
      result[dynamicPath] = convertedValue;
    }
  });

  return result as ModifyRecordValue<T>;
};

export default useTypedParams;
