import { type FC, Suspense } from 'react';
import { DrawerPage } from 'components';
import { Box } from '@mui/material';
import { EditPromptPage } from 'features/prompts';
import { useParams } from 'react-router';

const EditPromptRoute: FC = () => {
  const { promptId } = useParams();
  return (
    <DrawerPage>
      <Suspense fallback={<Box>Loading skeleton will be addedd</Box>}>
        <EditPromptPage promptId={promptId!} />
      </Suspense>
    </DrawerPage>
  );
};

export default EditPromptRoute;
