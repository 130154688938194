import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import type { QueryConfig } from 'lib/react-query';
import queryKeys from './queryKeys';
import type { FrontappConfig } from '../types';

export const getFrontappConfig = async (signal?: AbortSignal): Promise<FrontappConfig> => {
  const res = await apiClient.get<FrontappConfig>(`/frontapp/inbox-configs`, { signal });

  return res.data.map((config) => ({
    ...config,
    tags_to_skip_ai_tagging: config.tags_to_skip_ai_tagging ?? {},
  }));
};

type MutationFnType = () => ReturnType<typeof getFrontappConfig>;

type UseGetFrontappConfigOptions = {
  config?: QueryConfig<MutationFnType>;
};

export const useGetFrontappConfig = ({ config }: UseGetFrontappConfigOptions = {}) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getFrontappConfig(signal),
    queryKey: queryKeys().frontAppConfig(),
  });
};
